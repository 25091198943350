import AppBar from "@mui/material/AppBar";
import React from "react";
import Container from "@mui/material/Container";
import EmailIcon from "@mui/icons-material/Email";
import Typography from "@mui/material/Typography";

/**
 * React component for the footer of the application.
 * Visible on the homepage and the ADMIN homepage.
 * @type {(JSX.Element|string)[]}
 */
const footer = [<EmailIcon></EmailIcon>, "Mail: nb.natureasculture@gmail.com", "Officiell hemsida: www.mysite.com"];

function Footer() {
    return (
        <AppBar
            elevation={0}
            position="static"
            sx={{
                bgcolor: "#d9e0df",
            }}
        >
            <Container maxWidth="xl" sx={{ bgcolor: "backgrund.paper", p: 4 }} component="footer">
                {footer.map((footerSetting) => (
                    <Typography
                        key={footerSetting}
                        textAlign="center"
                        variant="subtitle1"
                        align="center"
                        color="text.secondary"
                        component="p"
                    >
                        {footerSetting}
                    </Typography>
                ))}
            </Container>
        </AppBar>
    );
}

export default Footer;
