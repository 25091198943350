import React from "react";
import Typography from "@mui/material/Typography";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { Link } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";

/**
 * React component used as a placeholder for account management
 * for a USER. This version currently has little/no working functionality
 * implemented and is more a placeholder that needs to be adjusted and tested.
 * @returns {JSX.Element}
 * @constructor
 */
export default function UserProfile() {
    const theme = createTheme({
        palette: {
            primary: {
                main: "#698681",
            },
            secondary: {
                main: "#d9e0df",
            },
        },
    });

    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs" sx={{ bgcolor: "secondary.main" }}>
                <Box
                    sx={{
                        marginTop: 13,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        bgcolor: "secondary.main",
                    }}
                >
                    <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
                        <PersonOutlineOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Redigera profil
                    </Typography>
                    <Box component="form" noValidate sx={{ mt: 3 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    autoComplete="given-name"
                                    name="firstName"
                                    fullWidth
                                    id="firstName"
                                    label="Redigera namn"
                                    autoFocus
                                    sx={{
                                        borderRadius: 1,
                                        backgroundColor: "white",
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    name="password"
                                    label="Redigera lösenord"
                                    type="password"
                                    id="password"
                                    autoComplete="new-password"
                                    sx={{
                                        borderRadius: 1,
                                        backgroundColor: "white",
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    variant="contained"
                                    startIcon={<DeleteIcon />}
                                    fullWidth
                                    sx={{ color: "white" }}
                                >
                                    Ta bort konto
                                </Button>
                            </Grid>
                        </Grid>
                        <Stack sx={{ pt: 3 }} direction="row" spacing={2} justifyContent="space-between">
                            <Link to="/" style={{ textDecoration: "none" }}>
                                <Button
                                    color="primary"
                                    sx={{ color: "white" }}
                                    variant="contained"
                                    style={{
                                        maxWidth: "180px",
                                        minWidth: "180px",
                                    }}
                                >
                                    Avbryt
                                </Button>
                            </Link>
                            <Button
                                color="primary"
                                sx={{ color: "white" }}
                                variant="contained"
                                style={{
                                    maxWidth: "180px",
                                    minWidth: "180px",
                                }}
                            >
                                Spara ändringar
                            </Button>
                        </Stack>
                    </Box>
                </Box>
            </Container>
        </ThemeProvider>
    );
}
