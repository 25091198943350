import * as React from "react";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Link } from "react-router-dom";
import Footer from "../main/Footer";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import NaturmarketImage from "../img/Naturmarket.png";

const theme = createTheme({
    palette: {
        primary: {
            main: "#698681",
        },
        secondary: {
            main: "#d9e0df",
        },
    },
});

/**
 * React component for the ADMIN homepage. Holds a collection of link/routes to the available ADMIN tools.
 * @returns {JSX.Element}
 * @constructor
 */
export default function Home() {
    return (
        <ThemeProvider theme={theme}>
            <AppBar position="relative" style={{ backgroundColor: "#8EA534", padding: "10px" }}>
                <Toolbar>
                    {/* Hero Section */}
                    <div style={{ display: "flex", alignItems: "center", padding: "1rem" }}>
                        <img
                            src={NaturmarketImage}
                            alt="Naturmärket"
                            style={{ width: "100px", height: "100px", marginRight: "1rem", borderRadius: "50%" }}
                        />
                        <Typography variant="h3" style={{ color: "white", fontSize: "1.5rem"}}>
                            Naturborgarmärket
                        </Typography>
                    </div>
                </Toolbar>
            </AppBar>
            <Link
                to="/home"
                style={{ textDecoration: "none", position: "absolute", top: 150, left: 0, margin: "16px" }}
            >
                <Button variant="contained" size="large" color="success">
                    Tillbaka
                </Button>
            </Link>
            <CssBaseline />
            <main>
                {/* Hero unit */}
                <Box
                    bgcolor="secondary.main"
                    sx={{
                        pt: 5,
                        pb: 5,
                    }}
                >
                    <Container maxWidth="sm">
                        <Typography
                            component="h1"
                            variant="h4"
                            align="center"
                            gutterBottom
                            sx={{
                                fontFamily: ["roboto"],
                                letterSpacing: 2,
                                m: 1,
                                pt: 4,
                                color: "primary",
                            }}
                        >
                            Välkommen Admin!
                        </Typography>
                    </Container>
                </Box>
                <Box bgcolor="secondary.main">
                    <Container sx={{ pt: 2, pb: 8 }} maxWidth="md">
                        {/* End hero unit */}
                        <Grid container spacing={2} paddingBottom="15px">
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <Card
                                    sx={{
                                        height: "100%",
                                        display: "flex",
                                        flexDirection: "column",
                                        borderColor: "white",
                                        borderStyle: "solid",
                                        borderRadius: "10px",
                                    }}
                                >
                                    <CardContent sx={{ flexGrow: 1 }}>
                                        <Typography gutterBottom variant="h5" component="h2">
                                            Redigera artlista
                                        </Typography>
                                        <Typography>
                                            Här kan du lägga till nya arter i en existerande lista eller skapa en ny
                                            lista
                                        </Typography>
                                    </CardContent>
                                    <CardActions>
                                        <Link to="/AdminSpeciesList" style={{ textDecoration: "none" }}>
                                            <Button variant="contained">Öppna</Button>
                                        </Link>
                                    </CardActions>
                                </Card>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} paddingBottom="15px">
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <Card
                                    sx={{
                                        height: "100%",
                                        display: "flex",
                                        flexDirection: "column",
                                        borderColor: "white",
                                        borderStyle: "solid",
                                        borderRadius: "10px",
                                    }}
                                >
                                    <CardContent sx={{ flexGrow: 1 }}>
                                        <Typography gutterBottom variant="h5" component="h2">
                                            Redigera quizfrågor
                                        </Typography>
                                        <Typography>Här kan du redigera och lägga till nya quizfrågor</Typography>
                                    </CardContent>
                                    <CardActions>
                                        <Link to="/AdminEditQuiz" style={{ textDecoration: "none" }}>
                                            <Button variant="contained">Öppna</Button>
                                        </Link>
                                    </CardActions>
                                </Card>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} paddingBottom="15px">
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <Card
                                    sx={{
                                        height: "100%",
                                        display: "flex",
                                        flexDirection: "column",
                                        borderColor: "white",
                                        borderStyle: "solid",
                                        borderRadius: "10px",
                                    }}
                                >
                                    <CardContent sx={{ flexGrow: 1 }}>
                                        <Typography gutterBottom variant="h5" component="h2">
                                            Redigera naturområde
                                        </Typography>
                                        <Typography>Här kan du redigera och lägga till ett nytt naturområde</Typography>
                                    </CardContent>
                                    <CardActions>
                                        <Link to="/AdminNatureMap" style={{ textDecoration: "none" }}>
                                            <Button variant="contained">Öppna</Button>
                                        </Link>
                                    </CardActions>
                                </Card>
                            </Grid>
                        </Grid>
                    </Container>
                </Box>
            </main>
            <Footer></Footer>
        </ThemeProvider>
    );
}
