import { createTheme, ThemeProvider } from "@mui/material/styles";
import React from "react";
import { useHistory } from "react-router-dom";
import Button from "@mui/material/Button";

const theme = createTheme({
    palette: {
        primary: {
            main: "#698681",
        },
        secondary: {
            main: "#d9e0df",
        },
    },
});

/**
 * Temporary React component that has been used to display an error message to the user
 * when he tries to access a page that should require the ADMIN role to view.
 * @param info
 * @returns {JSX.Element}
 * @constructor
 */
export default function AccessDenied({ info }) {
    const history = useHistory();

    return (
        <ThemeProvider theme={theme}>
            <div align="center">
                <div>{info}</div>
                <Button onClick={history.goBack}>Gå tillbaka</Button>
            </div>
        </ThemeProvider>
    );
}
