import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useHistory } from "react-router-dom";
import Bird from "../img/bird.jpg";
import { getToken } from "../http/tokenGET";
import { useState } from "react";

const footerStyle = {
    color: "black",
    textAlign: "center",
    position: "relative",
    width: "100%",
};

const defaultTheme = createTheme();

export default function SignUp() {
    const history = useHistory();
    const [errorMessage, setErrorMessage] = useState("");

    const handleSubmit = async (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const user = {
            firstname: data.get("firstname"),
            lastname: data.get("lastname"),
            email: data.get("email"),
            password: data.get("password"),
        };
        console.log(user.firstname);

        try {
            const token = await getToken();
            const response = await fetch(`${process.env.REACT_APP_API_URL}/register`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-TOKEN': token
                },
                credentials: 'include',
                body: JSON.stringify(user),
            });



            if (!response.ok) {
                const errorText = await response.text();
                setErrorMessage("Registreringen misslyckades: " + errorText);
                throw new Error(`Registration failed: ${errorText}`);
            }

            const result = await response.json();
            console.log('Success registration:', result);
            history.push("/signIn");
        } catch (error) {
            console.error('Error:', error);
            setErrorMessage("Ett fel inträffade vid registrering, vänligen försök igen.");
        }
    };

    return (
        <ThemeProvider theme={defaultTheme}>
            <Grid container component="main" sx={{height: "100vh", padding: "50px"}}>
                <CssBaseline/>
                <Grid
                    item
                    xs={false}
                    sm={4}
                    md={7}
                    sx={{
                        backgroundImage: `url(${Bird})`,
                        backgroundRepeat: "no-repeat",
                        backgroundColor: (t) => (t.palette.mode === "light" ? t.palette.grey[50] : t.palette.grey[900]),
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                    }}
                />
                <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                    <Box
                        sx={{
                            my: 8,
                            mx: 4,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                        }}
                    >
                        <Avatar sx={{m: 1, backgroundColor: "#8EA534"}}>
                            <LockOutlinedIcon/>
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            Registrera Konto
                        </Typography>
                        <Box component="form" noValidate onSubmit={handleSubmit} sx={{mt: 1}}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="firstname"
                                label="Förnamn"
                                name="firstname"
                                autoComplete="given-name"
                                autoFocus
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="lastname"
                                label="Efternamn"
                                type="text"
                                id="lastname"
                                autoComplete="family-name"
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="E-postadress"
                                name="email"
                                autoComplete="email"
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Lösenord (Minst 8 karaktärer)"
                                type="password"
                                id="password"
                                autoComplete="new-password"
                            />
                            {errorMessage && (
                                <Typography color="red" variant="body2">
                                    {errorMessage}
                                </Typography>
                            )}
                            <Button type="submit" fullWidth variant="contained" color="success" sx={{mt: 3, mb: 2}}>
                                Registrera
                            </Button>
                            <Grid container>
                                    <Link href="/signIn" variant="body2">
                                        {"Har du redan ett konto? Logga in"}
                                    </Link>
                            </Grid>
                        </Box>
                    </Box>
                    <div style={footerStyle}>
                        <Typography
                            variant="body2">&copy; {new Date().getFullYear()} Naturborgarmärket</Typography>
                    </div>
                </Grid>
            </Grid>
        </ThemeProvider>
    );
}