import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import Button from "@mui/material/Button";
import AppBar from "@mui/material/AppBar";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import useGET from "../http/useGET";
import NaturmarketImage from "../img/Naturmarket.png";
import falsterbo_strandbad from "../img/falsterbo_strandbad.jpeg";
import SearchAndFilter from "../SearchAndFilter";

const defaultTheme = createTheme();

const footerStyle = {
    backgroundColor: "#333",
    color: "white",
    padding: "2rem 0",
    textAlign: "center",
    position: "relative",
    width: "100%",
    marginTop: "auto",
};

export default function ChooseArea() {
    const { data: areas } = useGET(`${process.env.REACT_APP_API_URL}/areas`);
    const [filteredAreas, setFilteredAreas] = useState([]);
    const history = useHistory();

    useEffect(() => {
        if (areas) {
            setFilteredAreas(areas);
        }
    }, [areas]);
    const handleView = (areaName) => {
        history(`/NatureMap/${areaName}`);
    };
    return (
        <ThemeProvider theme={defaultTheme}>
            <CssBaseline />
            <Box
                sx={{
                    minHeight: "100vh",
                    display: "flex",
                    flexDirection: "column",
                }}
            >
                <AppBar position="relative" style={{ backgroundColor: "#8EA534", padding: "10px" }}>
                    <Toolbar>
                        <div style={{ display: "flex", alignItems: "center", padding: "1rem" }}>
                            <img
                                src={NaturmarketImage}
                                alt="Naturmärket"
                                style={{ width: "100px", height: "100px", marginRight: "1rem", borderRadius: "50%" }}
                            />
                            <Typography variant="h3" style={{ color: "white" }}>
                                Naturborgarmärket
                            </Typography>
                        </div>
                    </Toolbar>
                </AppBar>
                <main style={{ flex: 1 }}>
                    <Link
                        to="/home"
                        style={{ textDecoration: "none", position: "absolute", top: 150, left: 0, margin: "16px" }}
                    >
                        <Button variant="contained" size="large" color="success">
                            Tillbaka
                        </Button>
                    </Link>
                    <Box
                        sx={{
                            bgcolor: "background.paper",
                            pt: 8,
                            pb: 6,
                        }}
                    >
                        <Container maxWidth="sm">
                            <Typography component="h1" variant="h2" align="center" color="text.primary" gutterBottom>
                                Naturområde
                            </Typography>
                            <Button variant="contained" onClick={() => setFilteredAreas(areas)} color="primary">
                                Uppdatera lista
                            </Button>
                            <Typography variant="h5" align="center" color="text.secondary" paragraph>
                                Här kan du välja ett naturområde!
                            </Typography>
                            <SearchAndFilter
                                searchData={filteredAreas}
                                handleSearch={(searchTerm) => setFilteredAreas(
                                    areas.filter((area) => area.name.toLowerCase().includes(searchTerm.toLowerCase()))
                                )}
                                handleFilter={(selectedFilter) => setFilteredAreas(
                                    areas.filter((area) => area.category === selectedFilter)
                                )}
                                filterOptions={["Category1", "Category2", "Category3"]}
                            />
                        </Container>
                    </Box>
                    <Container sx={{ py: 2 }} maxWidth="md">
                        <Grid container spacing={4}>
                            {filteredAreas.map((area) => (
                                    <Grid item key={area.id} xs={12} sm={6} md={4}>
                                        <Card sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
                                            <img
                                                src={falsterbo_strandbad}
                                                alt={area.name}
                                                style={{
                                                    width: "100%",
                                                    height: "auto",
                                                    objectFit: "cover"
                                                }}
                                            />
                                            <CardContent sx={{flexGrow: 1}}>
                                                <Typography gutterBottom variant="h5" component="h2">
                                                {area.name}
                                                </Typography>
                                                <Typography>{area.description}</Typography>
                                            </CardContent>
                                            <CardActions>
                                                <Button size="small" onClick={() => handleView(area.name)}>
                                                    Visa
                                                </Button>
                                            </CardActions>
                                        </Card>
                                    </Grid>
                                ))}
                        </Grid>
                    </Container>
                </main>
                <div style={footerStyle}>
                    <Typography variant="body2">&copy; {new Date().getFullYear()} Naturborgarmärket</Typography>
                </div>
            </Box>
        </ThemeProvider>
    );
}