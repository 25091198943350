import React, { useState, useEffect } from "react";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import IconButton from "@mui/material/IconButton";
import StopIcon from "@mui/icons-material/Stop";

const useAudio = (url) => {
    const [audio] = useState(new Audio(url));
    const [playing, setPlaying] = useState(false);

    const toggle = () => setPlaying(!playing);

    useEffect(() => {
        playing ? audio.play() : audio.pause();
    }, [playing, audio]);

    useEffect(() => {
        audio.addEventListener("ended", () => setPlaying(false));
        return () => {
            audio.removeEventListener("ended", () => setPlaying(false));
        };
    }, [audio]);

    return [playing, toggle];
};

const Player = ({ url }) => {
    const [playing, toggle] = useAudio(url);

    return (
        <div>
            {url !== null ? (
                <IconButton
                    sx={{
                        color: "white",
                        borderRadius: 10,
                        padding: 2,
                        margin: 2,
                        backgroundColor: "#4caf50",
                        "&:hover": {
                            backgroundColor: "#66bb6a", // Lighter green on hover
                        },
                    }}
                    onClick={toggle}
                >
                    {playing ? <PauseIcon /> : <PlayArrowIcon />}
                </IconButton>
            ) : (
                <IconButton
                    sx={{
                        color: "white",
                        borderRadius: 5,
                        backgroundColor: "#4caf50",
                        "&:hover": {
                            backgroundColor: "#66bb6a", // Lighter green on hover
                        },
                    }}
                    disabled={true}
                >
                    <StopIcon />
                </IconButton>
            )}
        </div>
    );
};

export default Player;