import React from "react";
import ReactDOM from "react-dom/client";
import "./css/App.css";
import App from "./App";

/**
 * Top layer component of a React application.
 * Can also be used to enable/disable Strict mode of the
 * program during development.
 * Details: https://reactjs.org/docs/strict-mode.html
 * @type {Root}
 */
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    //<React.StrictMode>
    <App />,
    //</React.StrictMode>
);
