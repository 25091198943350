import * as React from "react";
import { useState } from "react";
import { AppBar, Toolbar, Typography, Button, TextField, Container, CssBaseline, Box } from "@mui/material";
import { useTheme, useMediaQuery } from "@mui/material";
import NaturmarketImage from "../img/Naturmarket.png";

function ShippingForm() {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const [formData, setFormData] = useState({
        name: "",
        address: "",
        city: "",
        zipCode: "",
        country: ""
    });

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        console.log("Shipping Details Submitted:", formData);
    };

    return (
        <div style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
            <CssBaseline />
            <AppBar position="relative" sx={{ backgroundColor: "#8EA434", padding: "10px" }}>
                <Toolbar>
                    <img src={NaturmarketImage} alt="Naturmarket" style={{ width: "80px", height: "80px", borderRadius: "50%" }} />
                    <Typography variant={isSmallScreen ? "h5" : "h3"} style={{ color: "white", marginLeft: "1rem" }}>
                        Naturborgarmärket
                    </Typography>
                </Toolbar>
            </AppBar>
            <Container component="main" maxWidth="sm" style={{ flexGrow: 1, padding: "2rem" }}>
                <Typography variant="h4" gutterBottom>
                    Leveransadress
                </Typography>
                <form onSubmit={handleSubmit}>
                    <TextField fullWidth label="Fullständigt namn" name="name" value={formData.name} onChange={handleChange} margin="normal" required />
                    <TextField fullWidth label="Adress" name="address" value={formData.address} onChange={handleChange} margin="normal" required />
                    <TextField fullWidth label="Stad" name="city" value={formData.city} onChange={handleChange} margin="normal" required />
                    <TextField fullWidth label="Postnummer" name="zipCode" value={formData.zipCode} onChange={handleChange} margin="normal" required />
                    <TextField fullWidth label="Land" name="country" value={formData.country} onChange={handleChange} margin="normal" required />
                    <Button type="submit" variant="contained" color="primary" style={{ marginTop: "1rem" }}>
                        Skicka
                    </Button>
                </form>
            </Container>
            <Box component="footer" sx={{ backgroundColor: "#323232", color: "white", padding: "1.5rem 0", textAlign: "center", marginTop: "auto" }}>
                <Typography variant="body2">&copy; {new Date().getFullYear()} Naturborgarmärket</Typography>
            </Box>
        </div>
    );
}

export default ShippingForm;
