import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";
import {
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    InputLabel,
    ListItemText,
    OutlinedInput,
    Select,
} from "@mui/material";
import Button from "@mui/material/Button";
import { Link} from "react-router-dom";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import MenuItem from "@mui/material/MenuItem";
import { useState } from "react";
import Paper from "@mui/material/Paper";
import useGET from "../http/useGET";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import NaturmarketImage from "../img/Naturmarket.png";

/**
 * React component allows user to mark the different species that they have found in the area.
 *
 * This component also holds the SpeciesInfo component where users can read
 * about the different species and see pictures of them.
 * @returns {JSX.Element}
 * @constructor
 */
export default function MarkingPage() {
    const {
        data: areas
    } = useGET(`${process.env.REACT_APP_API_URL}/areas`);

    const [areaSpecies, setAreaSpecies] = useState([]);

    const [areaName, setArea] = useState([]);

    const [selectedSpecies, setSelectedSpecies] = useState({});

    const fetchSpeciesFromArea = async (area) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/species/${area}`);

            const updatedSpecies = await response.json();
            setAreaSpecies(updatedSpecies);
        } catch (error) {
            console.error("Request failed:", error);
            alert("An error occurred while fetching species.");
        }
    }

    const theme = createTheme({
        palette: {
            primary: {
                main: "#333",
            },
            secondary: {
                main: "#d9e0df",
            },
        },
    });

    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 48 * 4.5 + 8,
                width: 250,
            },
        },
    };

    const handleSelectedArea = (area) => {
        const {
            target: { value },
        } = area;
        setArea(value);
        fetchSpeciesFromArea(value);
    };

    const [totalSelectedCheckboxes, setTotalSelectedCheckboxes] = useState(0);

    function handleCheck(id, checked) {
        setSelectedSpecies((prevState) => ({
            ...prevState,
            [`${id}`]: checked.valueOf(),
        }));

        setTotalSelectedCheckboxes(document.querySelectorAll("input[type=checkbox]:checked").length);
    }

    return (
        <ThemeProvider theme={theme}>
            <AppBar position="relative" style={{ backgroundColor: "#8EA534", padding: "10px" }}>
                <Toolbar>
                    {/* Hero Section */}
                    <div style={{ display: "flex", alignItems: "center", padding: "1rem" }}>
                        <img
                            src={NaturmarketImage}
                            alt="Naturmärket"
                            style={{ width: "100px", height: "100px", marginRight: "1rem", borderRadius: "50%" }}
                        />
                        <Typography variant="h3" style={{ color: "white" }}>
                            Naturborgarmärket
                        </Typography>
                    </div>
                </Toolbar>
            </AppBar>
            <Link
                to="/home"
                style={{ textDecoration: "none", position: "absolute", top: 150, left: 0, margin: "16px" }}
            >
                <Button variant="contained" size="large" color="success">
                    Tillbaka
                </Button>
            </Link>
            <Box
                sx={{
                    bgcolor: "primary",
                    pt: 5,
                    pb: 3,
                }}
            >
                <Container maxWidth="sm">
                    <Typography
                        component="h1"
                        variant="h3"
                        align="center"
                        gutterBottom
                        sx={{
                            fontFamily: ["Roboto", "arial"],
                            letterSpacing: 3,
                            m: 1,
                            color: "primary",
                            position: "relative",
                        }}
                    >
                        MARKERA ARTER
                    </Typography>
                    <Box
                        sx={{
                            background: "linear-gradient(to bottom, #0647a3, #00d4ff)",
                            py: 3,
                            borderRadius: "20px",
                        }}
                    >
                        <Box sx={{ m: 1, p: 2 }}>
                            <Typography align="center">
                                Välj arter från listan som du lyckas hitta under ditt letande. När du valt 40 arter så
                                kommer du ha möjlighet att starta ett kompetensquiz på dina markerade arter. Lycka till!
                            </Typography>
                        </Box>
                        <Typography
                            component="h1"
                            variant="h3"
                            color="primary"
                            borderRadius={10}
                            sx={{
                                mx: "auto",
                                bgcolor: "white",
                                width: "50%",
                                textAlign: "center",
                                padding: "10px",
                            }}
                        >
                            {totalSelectedCheckboxes}/40
                        </Typography>
                    </Box>
                </Container>
            </Box>
            <Box bgcolor="secondary.main">
                <Container maxWidth="sm">
                    <Stack sx={{ width: 1, pb: 2 }} direction="row" spacing={2} justifyContent="space-between">
                    </Stack>
                    <TextField
                        color="primary"
                        id="art"
                        label="Sök efter art här"
                        name="Art"
                        autoComplete="Art"
                        variant="outlined"
                        sx={{ width: 1, borderRadius: 1, mb: 2 }}
                        style={{ backgroundColor: "white" }}
                    ></TextField>
                    <FormControl sx={{ width: 1, pb: 2 }} color="primary">
                        <InputLabel id="demo-multiple-checkbox-label">Välj område</InputLabel>
                        <Select
                            style={{ backgroundColor: "white", borderRadius: 35 }}
                            labelId="demo-multiple-checkbox-label"
                            id="demo-multiple-checkbox"
                            value={areaName}
                            onChange={handleSelectedArea}
                            input={<OutlinedInput label="Välj område" />}
                            MenuProps={MenuProps}
                        >
                            {areas &&
                                areas.map((x) => (
                                    <MenuItem key={x.name} value={x.name}>
                                        <ListItemText primary={x.name} />
                                    </MenuItem>
                                ))}
                        </Select>
                    </FormControl>
                    <Stack sx={{ width: 1, pb: 2 }} direction="row" spacing={2} justifyContent="space-between">
                        <Link to="/NatureMap" style={{ textDecoration: "none" }}>
                            <Button
                                size="large"
                                color="success"
                                style={{ maxWidth: "125px", minWidth: "125px" }}
                                sx={{ color: "primary" }}
                                variant="contained"
                            >
                                Tillbaka
                            </Button>
                        </Link>
                        <Link
                            to={{ pathname: "/Quiz", state: { speciesOut: selectedSpecies }, areaName: areaName }}
                            style={{ textDecoration: "none" , pointerEvents: totalSelectedCheckboxes < 40 ? "none" : "auto"}}
                        >
                            <Button
                                size="large"
                                color="success"
                                sx={{ color: "primary" }}
                                variant="contained"
                                disabled={totalSelectedCheckboxes < 40}
                                onClick={() => console.log("Selected species: ", selectedSpecies, "totalSelectedCheckBoxes: ", totalSelectedCheckboxes)}
                            >
                                Gå vidare
                            </Button>
                        </Link>
                    </Stack>
                </Container>
            </Box>
            <Box
                sx={{
                    bgcolor: "primary",
                    pb: 10,
                    pt: 3,
                }}
            >
                <Container maxWidth="sm">
                    <Paper style={{ maxHeight: 590 }} sx={{ bgcolor: "secondary.main" }}>
                        <FormControl fullWidth>
                            <InputLabel id="select-species-label">Välj arter för att markera</InputLabel>
                            <Select
                                labelId="select-species-label"
                                label="Välj arter för att markera"
                                style={{ width: "100%" }}
                            >
                                <Grid>
                                    {areaSpecies &&
                                        areaSpecies
                                            .sort((a, b) => a.name.localeCompare(b.name))
                                            .map((species) => (
                                                <Grid item xs={12} sm={12} md={12} lg={12} key={species.id}>
                                                    <Grid sx={{ pb: 2 }}>
                                                        <Card
                                                            sx={{ width: 1, display: "flex", flexDirection: "column" }}
                                                        >
                                                            <CardContent>
                                                                <FormGroup>
                                                                    <FormControlLabel
                                                                        value={species.id}
                                                                        labelPlacement="start"
                                                                        control={
                                                                            <Checkbox
                                                                                sx={{ mr: 1 }}
                                                                                onChange={(event, checked) =>
                                                                                    handleCheck(
                                                                                        event.target.value,
                                                                                        checked,
                                                                                    )
                                                                                }
                                                                                color="success"
                                                                            />
                                                                        }
                                                                        label={species.name}
                                                                        sx={{
                                                                            justifyContent: "space-between",
                                                                            padding: "15px",
                                                                        }}
                                                                    />
                                                                </FormGroup>
                                                            </CardContent>
                                                        </Card>
                                                    </Grid>
                                                </Grid>
                                            ))}
                                </Grid>
                            </Select>
                        </FormControl>
                    </Paper>
                </Container>
            </Box>
        </ThemeProvider>
    );
}
