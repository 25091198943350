export const getToken = async () => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/token`, {
            credentials: 'include',
        });
        console.log('API URL:', process.env.REACT_APP_API_URL);
        console.log('Request Headers:', response.headers);
        console.log('Response Status:', response.status);
        console.log('Response Headers:', response.headers);

        if (!response.ok) {
            const errorText = await response.text(); // Läs in felmeddelandet från svaret
            console.error('Failed to fetch token:', errorText);
            throw new Error(`Failed to fetch token: ${errorText}`);
        }

        const data = await response.json();
        console.log('Fetched token:', data.token);
        return data.token;
    } catch (error) {
        console.error('Error fetching token:', error);
        throw error; 
    }
};
