import * as React from "react";
import { Link, useHistory } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import NaturmarketImage from "../img/Naturmarket.png";
import Grid from "@mui/material/Grid";
import { useTheme, useMediaQuery } from "@mui/material";
import { useCookies } from "react-cookie";
import Box from "@mui/material/Box";
import VitfyrkantImage from "../img/vitfyrkant.jpeg";

const sectionStyle = {
    padding: "2rem 2rem",
    background: "#f8f8f8",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
    borderRadius: "8px",
    margin: "1rem auto",
    maxWidth: "900px",
    flex: 1,
    height: "100%",
    transition: "transform 0.3s ease-in-out",
    "&:hover": {
        transform: "scale(1.05)",
    },
};

const footerStyle = {
    backgroundColor: "#323232",
    color: "white",
    padding: "1.5rem 0",
    textAlign: "center",
    width: "100%",
    marginTop: "auto", // Trycker ner footern längst ner på sidan
};
const mainContentStyle = {
    flexGrow: 1,
    padding: "2rem 2rem",
    display: "flex",
};

function Home() {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));


    const [cookies] = useCookies(["access"]); // Använd cookies men rensa dem inte här
    const history = useHistory();

    const isLoggedIn = Boolean(cookies.access);
    const handleLogout = () => {
        history.push("/signout");  // Navigera till utloggningssidan
    };

    return (
        <div style={{display: "flex", flexDirection: "column", minHeight: "100vh"}}>
            <CssBaseline/>
            <AppBar position="relative" sx={{backgroundColor: "#8EA434", padding: "10px"}}>
                <Toolbar>
                    {/* Hero Section */}
                    <div style={{display: "flex", alignItems: "center", padding: "0.1rem"}}>
                        <img
                            src={NaturmarketImage}
                            alt="Naturmärket"
                            style={{width: "80px", height: "80px", borderRadius: "50%"}}
                        />
                        <Typography variant={isSmallScreen ? "h5" : "h3"} style={{color: "white"}}>
                            Naturborgarmärket
                        </Typography>
                    </div>
                    {/* Login / Log Out Button and Icon */}
                    <div style={{display: "flex", alignItems: "right", marginLeft: "1rem"}}>
                        {isLoggedIn ? (
                            <Button
                                variant="contained"
                                style={{backgroundColor: "#fff", color: "#1976d3"}}
                                onClick={handleLogout}
                                startIcon={<AccountCircleIcon/>}
                            >
                                {!isSmallScreen && "Logga ut"}
                            </Button>
                        ) : (
                            <Link to="/signIn" style={{textDecoration: "none"}}>
                                <Button
                                    variant="contained"
                                    sx={{backgroundColor: "#fff", color: "#1976d3"}}
                                    startIcon={<AccountCircleIcon/>}
                                >
                                    {!isSmallScreen && "Logga in"}
                                </Button>
                            </Link>
                        )}
                    </div>
                </Toolbar>
            </AppBar>

            <main sx={{ ...mainContentStyle, flex: 1 }}>
                <Grid container spacing={2} alignItems="stretch" wrap={isSmallScreen ? "wrap" : "nowrap"}>
                    <Grid item xs={12} sm={3} sx={{ display: "flex", marginLeft: "3rem", flexShrink: 1}}>
                        <Box sx={{
                            ...sectionStyle,
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                            flex: 1
                        }}>
                            <div>
                                <Typography variant="h4" sx={{fontSize: "1.7rem"}}>Gå direkt till quizet!</Typography>
                                <Typography variant="body1" sx={{marginTop: "0.5rem"}}>
                                    Testa din kunskap om Sveriges naturområden.
                                </Typography>
                                <img src={VitfyrkantImage} alt="Vit fyrkant"
                                     style={{width: "100%", marginTop: "1rem"}}/>
                            </div>
                                <Link to="/MarkingPage/" style={{textDecoration: "none"}}>
                                    <Button variant="outlined" style={{
                                        marginTop: "1rem",
                                        backgroundColor: "#2E7D33",
                                        color: "#fff"
                                    }}>
                                        Starta
                                    </Button>
                                </Link>
                        </Box>
                    </Grid>

                    <Grid item xs={12} sm={3} sx={{display: "flex", flexShrink: 1 }}>
                        <Box sx={{
                            ...sectionStyle,
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                            flex: 1
                        }}>
                            <div>
                                <Typography variant="h4" sx={{fontSize: "1.7rem"}}>Välj naturområde</Typography>
                                <Typography variant="body1" style={{marginTop: "0.5rem"}}>
                                    Gå vidare för att välja naturområde.
                                </Typography>
                                <img src={VitfyrkantImage} alt="Vit fyrkant"
                                     style={{width: "100%", marginTop: "1rem"}}/>
                            </div>
                                <Link to="/ChooseArea" style={{textDecoration: "none"}}>
                                    <Button variant="outlined" color="primary" style={{
                                        marginTop: "1rem",
                                        backgroundColor: "#2E7D33",
                                        color: "#fff"
                                    }}>
                                        Start
                                    </Button>
                                </Link>
                        </Box>
                    </Grid>

                    <Grid item xs={12} sm={3} sx={{display: "flex", flexShrink: 1 }}>
                        <Box sx={{
                            ...sectionStyle,
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                            flex: 1
                        }}>
                            <div>
                                <Typography variant="h4" sx={{fontSize: "1.7rem"}}>Information om arter</Typography>
                                <Typography variant="body1" sx={{marginTop: "0.5rem"}}>
                                    Här kan du läsa på om olika arter som finns i olika naturområden. Se bilder och
                                    beskrivningar.
                                </Typography>
                                <img src={VitfyrkantImage} alt="Vit fyrkant"
                                     style={{width: "100%", marginTop: "1rem"}}/>
                            </div>
                                <Link to="/species-info" style={{textDecoration: "none"}}>
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        sx={{
                                            marginTop: "1rem",
                                            backgroundColor: "#2E7D33",
                                            color: "#fff"
                                        }}
                                    >
                                        Utforska
                                    </Button>
                                </Link>
                        </Box>
                    </Grid>

                    <Grid item xs={12} sm={3} sx={{display: "flex", marginRight: "3rem", flexShrink: 1}}>
                        <Box sx={{
                            ...sectionStyle,
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                            flex: 1
                        }}>
                            <div>
                                <Typography variant="h4" sx={{fontSize: "1.7rem"}}>Om Naturborgarmärket</Typography>
                                <Typography variant="body1" sx={{marginTop: "0.5rem"}}>
                                    Läs mer om Naturborgarmärket, allemansrätten och se vilka som stödjer vår
                                    utveckling.
                                </Typography>
                                <img src={VitfyrkantImage} alt="Vit fyrkant"
                                     style={{width: "100%", marginTop: "1rem"}}/>
                            </div>
                                <Link to="/AboutNB" style={{textDecoration: "none"}}>
                                    <Button variant="outlined" color="primary" sx={{
                                        marginTop: "1rem",
                                        backgroundColor: "#2E7D33",
                                        color: "#fff"
                                    }}>
                                        Läs mer
                                    </Button>
                                </Link>
                        </Box>
                    </Grid>
                </Grid>
            </main>

            <div style={footerStyle}>
                <Typography variant="body2">&copy; {new Date().getFullYear()} Naturborgarmärket</Typography>
            </div>
        </div>
    );
}

export default Home;
