import React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import { Typography } from "@mui/material";
import { ImageList, ImageListItem } from "@mui/material";
import EU from "../img/EU.jpg";
import Leader from "../img/Leader.jpg";
import SpbSkane from "../img/SpbSkane.png";
import Swedbank from "../img/Swedbank.png";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { Link } from "react-router-dom";
import falsterbostrand from "../img/falsterbostrand.jpg";
import {ArrowUpward, DoubleArrow, KeyboardDoubleArrowUp} from "@mui/icons-material";

const scrollToSection = (sectionId) => {
    const targetSection = document.getElementById(sectionId);
    if (targetSection) {
        targetSection.scrollIntoView({ behavior: "smooth" });
    }
};

const footerStyle = {
    backgroundColor: "#333",
    color: "white",
    padding: "2rem 0",
    textAlign: "center",
    position: "relative",
    width: "100%",
    marginTop: "auto", // Trycker ner footern längst ner på sidan
};

export default function AboutNB() {
    return (
        <>
            <CssBaseline />
            <main>
                {/* Section 1 */}
                <div
                    id="section1"
                    className="section"
                    style={{
                        backgroundImage: `url(${falsterbostrand})`,
                        backgroundSize: "cover",
                    }}
                >
                    <Link
                        to="/home"
                        style={{
                            textDecoration: "none",
                            position: "absolute",
                            top: 0,
                            left: 0,
                            margin: "16px",
                        }}
                    >
                        <Button variant="contained" size="large" color="success">
                            Tillbaka
                        </Button>
                    </Link>
                    <Container>
                        <h1>Om Naturborgarmärket</h1>
                        <Typography variant="body1" sx={{ fontSize: "1.2rem" }}>
                            Naturborgarmärket syftar till att utveckla allmänhetens kunskap om olika arter som finns i
                            vår natur. Idén går ut på att skapa ett interaktivt lärande där användaren måste befinna
                            sig i naturen och aktivt leta efter och se olika arter, där kunskapen om dessa arter sedan testas
                            i ett kunskapstest. Om användaren klarar kunskapstestet så belönas detta med ett
                            fysiskt naturborgarmärke som erhålls genom beställning efter avklarat test. Detta kan sedan
                            t.ex. sys på en ryggsäck eller sättas fast på jackan.
                        </Typography>
                    </Container>
                    <div style={{ position: "relative", top: "-270px" }}>
                        <Button
                            variant="contained"
                            size="large"
                            color="success"
                            onClick={() => scrollToSection("section2")}
                            style={{ width: "200px", padding: "10px", marginTop: "300px" }}
                        >
                            <ArrowDownwardIcon />
                        </Button>
                    </div>
                </div>

                {/* Section 2 */}
                <div id="section2" className="section">
                    <Container>
                        <h1>Utvecklingen av Naturborgarmärket stödjs av:</h1>
                        <ImageList variant="masonry" cols={3} gap={50}>
                            {[
                                { img: Swedbank, title: "Swedbank" },
                                { img: SpbSkane, title: "Sparbanksstiftelsen Skåne" },
                                { img: EU, title: "EU" },
                                { img: Leader, title: "Leader" },
                            ].map((item) => (
                                <ImageListItem key={item.img}>
                                    <img src={item.img} alt={item.title} loading="lazy" />
                                </ImageListItem>
                            ))}
                        </ImageList>
                    </Container>
                    <div>
                        <Button
                            variant="contained"
                            size="large"
                            color="success"
                            onClick={() => scrollToSection("section1")}
                            style={{
                                width: "200px",
                                padding: "10px",
                                marginTop: "20px",
                                marginRight: "10px",
                            }}
                        >
                            <ArrowUpwardIcon />
                        </Button>
                        <Button
                            variant="contained"
                            size="large"
                            color="success"
                            onClick={() => scrollToSection("section3")}
                            style={{ width: "200px", padding: "10px", marginTop: "20px" }}
                        >
                            <ArrowDownwardIcon />
                        </Button>
                    </div>
                </div>

                {/* Section 3 */}
                <div id="section3" className="section">
                    <Container>
                        <h1>Naturborgarmärket och allemansrätten</h1>
                        <Typography variant="body1" sx={{ fontSize: "1.2rem" }}>
                            Tack vare allemansrätten kan vi röra oss fritt i den svenska naturen. Allemansrätten
                            fungerar om alla tar ansvar för att bedöma vad som är lämpligt just här och nu, genom att
                            läsa landskapet och bedöma situationen. Olika aktiviteter, platser och tidpunkter avgör vad
                            du kan göra eller inte. Det är vårt gemensamma ansvar att använda denna gamla
                            sedvana med omtanke. Därför är det viktigt att ta reda på vilka rättigheter och skyldigheter
                            som ingår i allemansrätten. Allemansrätten är ingen lag, däremot omges allemansrätten av
                            lagar och regler som sätter gränser för vad som är tillåtet. I länken nedan kan du läsa mer.
                        </Typography>
                    </Container>
                    <div>
                        <Button
                            variant="contained"
                            size="large"
                            color="success"
                            onClick={() => scrollToSection("section2")}
                            style={{
                                width: "200px",
                                padding: "10px",
                                marginTop: "20px",
                                marginRight: "10px",
                            }}
                        >
                            <ArrowUpwardIcon />
                        </Button>
                        <Button
                            variant="contained"
                            size="large"
                            color="success"
                            onClick={() => scrollToSection("section4")}
                            style={{ width: "200px", padding: "10px", marginTop: "20px" }}
                        >
                            <ArrowDownwardIcon />
                        </Button>
                    </div>
                </div>

                {/* Section 4 */}
                <div id="section4" className="section">
                    <Container>
                        <h1>Allemansrätten är en fantastisk möjlighet för alla att röra sig fritt i naturen</h1>
                        <Typography variant="body1" sx={{ fontSize: "1.2rem" }}>
                            Du använder dig av allemansrätten när du går en promenad i skogen, paddlar kajak, klättrar eller sitter på en sten och tänker.
                            Oftast är den alldeles naturlig för oss. För att alla ska kunna njuta av naturen behöver vi ta hänsyn till natur och djurliv och visa
                            hänsyn mot markägare och andra som är ute. Vi kan sammanfatta det i att inte störa – inte förstöra!
                        </Typography>
                        <h2>Vandra, cykla, rida…</h2>
                        <Typography variant="body1" sx={{ fontSize: "1.2rem" }}>
                            Vi har en härlig möjlighet att vara i naturen och göra många olika aktiviteter. Vi svenskar
                            är väldigt intresserade av uteliv och natur. För att alla ska kunna njuta av naturen finns
                            det några saker som är bra att tänka på: <br /> <br />
                            • Tänk på att inte skada gröda, skogsplantering eller annan känslig mark. <br />
                            • För att inte störa de som bor i området, tänk på att inte gå över eller vara på någons
                            tomt. De boende har rätt att vara ostörda. <br />
                            • Tänk på att inte hindra markägare i deras
                            verksamhet. <br />
                            • När man rider eller cyklar i terrängen är det risk för markskador. Tänk på att
                            inte rida eller cykla över mjuka, ömtåliga marker, i markerade motionsspår, skidspår
                            eller vandringsleder. <br />
                            •  Om du passerar inhägnad betesmark, tänk
                            på att inte störa djuren eller skada stängsel.
                            Stäng grinden efter dig så att boskapen inte
                            kommer lös.
                        </Typography>
                    </Container>
                    <div>
                        <Button
                            variant="contained"
                            size="large"
                            color="success"
                            onClick={() => scrollToSection("section3")}
                            style={{
                                width: "200px",
                                padding: "10px",
                                marginTop: "20px",
                                marginRight: "10px",
                            }}
                        >
                            <ArrowUpwardIcon />
                        </Button>
                        <Button
                            variant="contained"
                            size="large"
                            color="success"
                            onClick={() => scrollToSection("section5")}
                            style={{ width: "200px", padding: "10px", marginTop: "20px" }}
                        >
                            <ArrowDownwardIcon />
                        </Button>
                    </div>
                </div>

                {/* Section 5 */}
                <div id="section5" className="section">
                    <Container>
                        <h2>Camping</h2>
                        <Typography variant="body1" sx={{ fontSize: "1.2rem" }}>
                            Tälta gärna något dygn i naturen! Tänk på att tälta där det är lämpligt, alltså
                            inte där det bedrivs jordbruk och inte för nära bostadshus. Ju större risk för att störa
                            någon, desto större anledning att be om lov. Att ta hänsyn är än viktigare vid fri camping
                            med husvagn och husbil. Sån camping begränsas av terrängkörningslagen.
                        </Typography>
                        <h2>Ta med skräpet</h2>
                        <Typography variant="body1" sx={{ fontSize: "1.2rem" }}>
                            Vi vill alla ha en ren natur, fri från skräp. Så ta med dig skräpet. Glas, burkar, platstpåsar
                            och kapsyler kan skada både människor och djur. Ställ därför aldrig soppåsen bredvid ett
                            fullt sopställ.
                        </Typography>
                        <h2>Elda</h2>
                        <Typography variant="body1" sx={{ fontSize: "1.2rem" }}>
                            Lägerelden sätter krydda på vårt friluftsliv och du får göra upp eld i naturen. Men
                            eldning skapar oro bland många markägare eftersom skog för stora värden brinner upp
                            varje år på grund av slarv med lägereldar. För att minska risken för att elden ska
                            spridas finns det några saker som är bra att tänka på: <br/> <br/>
                            • Inte elda om det råder minsta fara för brand – vid torrt väder råder ofta eldningsförbud.<br/>
                            • Välj en plats där det inte är risk att elden sprider sig. <br/>
                            • Tänk på att inte elda direkt på, eller alldeles intill berghällar. De spricker sönder och
                            får sår som aldrig läker. <br/>
                            • När du har eldat – släck elden ordentligt. <br/>
                            • Lämna inga spår efter elden i form av eldstad eller liknande.
                        </Typography>
                    </Container>
                    <div>
                        <Button
                            variant="contained"
                            size="large"
                            color="success"
                            onClick={() => scrollToSection("section4")}
                            style={{
                                width: "200px",
                                padding: "10px",
                                marginTop: "20px",
                                marginRight: "10px",
                            }}
                        >
                            <ArrowUpwardIcon />
                        </Button>
                        <Button
                            variant="contained"
                            size="large"
                            color="success"
                            onClick={() => scrollToSection("section6")}
                            style={{ width: "200px", padding: "10px", marginTop: "20px" }}
                        >
                            <ArrowDownwardIcon />
                        </Button>
                    </div>
                </div>

                {/* Section 6 */}
                <div id="section6" className="section">
                    <Container>
                        <h2 style={{marginTop: "0px"}}> Plocka blommor, bär, svamp, kvistar…</h2>
                        <Typography variant="body1" sx={{ fontSize: "1.2rem" }}>
                            Plocka gärna blommor, bär, svamp, ris och grenar från marken. <br/>
                            För att inte skada naturen finns det några saker som är bra att tänka på: <br/> <br/>
                            • Tänk på att vissa växter är fridlysta och att de inte får plockas. Länsstyrelsen kan ge
                            information om fridlysta växter. Orkidéer är fridlysta i hela landet. <br/>
                            • Tänk på att inte ta kvistar, grenar, näver och
                            bark från växande träd. De kan skadas. <br/>
                        </Typography>
                        <h2>Hundar</h2>
                        <Typography variant="body1" sx={{ fontSize: "1.2rem" }}>
                            Hundar får självklart följa med i naturen. 1 mars – 20 augusti är djurlivet känsligt och
                            då får hundar inte springa lösa. Även under annan tid på året
                            måste du ha din hund under sån uppsikt att den inte stör
                            eller skadar djurlivet och andra människor. I vissa delar av landet råder kopplingstvång.
                        </Typography>
                        <h2>Bad och båt</h2>
                        <Typography variant="body1" sx={{ fontSize: "1.2rem" }}>
                            Allemansrätten gäller både på land och på vatten. Du får bada vid stränder, åka båt
                            nästan överallt, förtöja och övernatta något dygn i båten. Samma hänsyn mot omgiv
                            ningen som på land gäller. Alltså inte störa – inte förstöra. För att alla ska kunna njuta av naturen vid
                            och i vattnet finns det några saker som är bra att tänka på: <br/> <br/>
                            • Tänk på att inte förtöja eller gå iland vid
                            en tomt eller där det är särskilt tillträdes
                            förbud, till exempel till skydd för fågellivet
                            och sälar. <br/>
                            • Det går bra att förtöja båten tillfälligt vid
                            någon annans brygga om den inte hindrar
                            ägaren. Men inte om det är en tomt för
                            stås. Kontakta gärna markägaren. <br/>
                            • Det kan finnas särskilda regler, till exempel
                            fartbegränsning och tillträdesförbud. <br/>
                            • Tänk på att motorbåtar behöver tänka
                            mer på hänsynen än tystare båtar.
                        </Typography>
                    </Container>
                    <div>
                        <Button
                            variant="contained"
                            size="large"
                            color="success"
                            onClick={() => scrollToSection("section5")}
                            style={{
                                width: "200px",
                                padding: "10px",
                                marginTop: "20px",
                                marginRight: "10px",
                            }}
                        >
                            <ArrowUpwardIcon />
                        </Button>
                        <Button
                            variant="contained"
                            size="large"
                            color="success"
                            onClick={() => scrollToSection("section7")}
                            style={{ width: "200px", padding: "10px", marginTop: "20px" }}
                        >
                            <ArrowDownwardIcon />
                        </Button>
                    </div>
                </div>

                {/* Section 7 */}
                <div id="section7" className="section">
                    <Container>
                        <h2>Jakt och fiske</h2>
                        <Typography variant="body1" sx={{ fontSize: "1.2rem" }}>
                            Jakt och fiske ingår inte i allemansrätten. Men du får fiska fritt med spö och vissa
                            andra handredskap längs kusterna och i våra fem största sjöar: Vänern, Vättern, Mälaren,
                            Hjälmaren och Storsjön. För fiske i andra vatten krävs fiskekort eller annat tillstånd.
                            Längs Norrlandskusten, Västkusten och Skånes sydkust ingår även andra fiskemetoder i det fria fisket.
                            Annars omfattas inte nätfiske, angelfiske, trolling eller dragrodd
                            av det fria fisket, och inte heller laxfiske vid Norrlandskusten.
                            För att alla ska kunna njuta av naturen och vi inte ska skada naturen finns det några
                            saker som är bra att tänka på: <br/> <br/>
                            • Tänk på att alltid ta reda på de regler som
                            gäller där du vill fiska. <br/>
                            • Lämna inte linor och krokar i naturen – de
                            kan bli dödsfällor för djuren.<br/>
                            • Lämna djurens ungar och bon i fred. <br/>
                            • Man får inte ta fågelägg. Det räknas som jakt. Alla vilda djur och fåglar är fredade.
                            Jakt får bedrivas enligt bestämmelser i jaktförordningen. Djurarter som hotas
                            av utrotning är fridlysta, till exempel alla grodor och ormar.
                        </Typography>
                        <h2> Organiserat friluftsliv</h2>
                        <Typography variant="body1" sx={{ fontSize: "1.2rem" }}>
                            Äventyrsturism och annat organiserat friluftsliv har stor möjlighet att använda
                            allemansrätten för aktiviteter. Allemansrätten kan användas kommersiellt och av
                            många användare samtidigt. Det finns stora krav på den som arrangerar organiserade
                            aktiviteter på annans mark.  <br/> <br/>
                            • Skaffa nödvändiga kunskaper <br/>
                            • Välj lämplig plats <br/>
                            • Genomför skydds- och försiktighetsåtgärder <br/>
                            • Informera deltagarna om allemansrätten
                            Det allra bästa är om arrangören tar kontakt
                            med markägare, kommun och länsstyrelse
                            innan aktiviteterna inleds. <br/>
                        </Typography>
                    </Container>
                    <div>
                        <Button
                            variant="contained"
                            size="large"
                            color="success"
                            onClick={() => scrollToSection("section6")}
                            style={{
                                width: "200px",
                                padding: "10px",
                                marginTop: "20px",
                                marginRight: "10px",
                            }}
                        >
                            <ArrowUpwardIcon />
                        </Button>
                        <Button
                            variant="contained"
                            size="large"
                            color="success"
                            onClick={() => scrollToSection("section8")}
                            style={{ width: "200px", padding: "10px", marginTop: "20px" }}
                        >
                            <ArrowDownwardIcon />
                        </Button>
                    </div>
                </div>

                {/* Section 8 */}
                <div id="section8" className="section">
                    <Container>
                        <h2>I tätortsnära natur</h2>
                        <Typography variant="body1" sx={{ fontSize: "1.2rem" }}>
                            I den tätortsnära naturen blir allemansrätten än viktigare. Den gör det möjligt för många
                            människor att ta del av naturen på hemma plan, utan att ta sig nånstans. I tätorten
                            vistas många människor samtidigt, ibland på trånga ytor. Allemansrätten gör det lättare
                            för oss alla att på ett hänsynsfull sätt vara i naturen och undvika konflikter och slitage
                            på marken.
                        </Typography>
                        <h2>Inga motorfordon i terrängen</h2>
                        <Typography variant="body1" sx={{ fontSize: "1.2rem" }}>
                            Det finns ingen motoriserad allemansrätt. Enligt Terrängkörningslagen är det förbjudet
                            att köra bil, MC, moped och andra motor drivna fordon på barmark i terrängen.
                            Det är heller inte tillåtet att köra med motordrivna fordon på enskilda vägar som
                            är avstängda för motortrafik. Sånt förbud ska vara utmärkt med vägmärke.
                        </Typography>
                        <h2>Skyddad natur</h2>
                        <Typography variant="body1" sx={{ fontSize: "1.2rem" }}>
                            anslagstavlor kan man läsa vilka regler som gäller. Skogen är inte farlig
                            I nationalparker, naturreservat, natura 2000-område eller
                            andra skyddade områden gäller speciella regler. Ibland är
                            allemansrätten begränsad, ibland utökad. Information finns vid entréer till parker etc.
                        </Typography>
                        <h2>Om du vill veta mer</h2>
                        <Typography>
                            Du kan läsa mer om allemansrätten på <br/>
                            <a href="https://www.naturvardsverket.se/allemansratten" target="_blank" rel="noopener noreferrer">
                                www.naturvardsverket.se/allemansratten
                            </a>
                            <br/>
                            Du kan också ringa din kommun eller länsstyrelse.
                        </Typography>
                    </Container>
                    <div>
                        <Button
                            variant="contained"
                            size="large"
                            color="success"
                            onClick={() => scrollToSection("section7")}
                            style={{
                                width: "200px",
                                padding: "10px",
                                marginTop: "20px",
                                marginRight: "10px",
                            }}
                        >
                            <ArrowUpwardIcon />
                        </Button>
                        <Button
                            variant="contained"
                            size="large"
                            color="success"
                            onClick={() => scrollToSection("section1")}
                            style={{ width: "200px", padding: "10px", marginTop: "20px" }}
                        >
                            <KeyboardDoubleArrowUp/>
                        </Button>
                    </div>
                    <div style={footerStyle}>
                        <Typography variant="body2">&copy; {new Date().getFullYear()} Naturborgarmärket</Typography>
                    </div>
                </div>
            </main>
        </>
    );
}