import React, { useEffect, useRef, useState } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { FormControl, InputLabel, List, OutlinedInput, Select } from "@mui/material";
import { Link } from "react-router-dom";
import useGET from "../http/useGET";
import AddIcon from "@mui/icons-material/Add";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import CssBaseline from "@mui/material/CssBaseline";
import MenuItem from "@mui/material/MenuItem";
import { ImageHandler } from "../hooks/ImageHandler";
import Toolbar from "@mui/material/Toolbar";
import NaturmarketImage from "../img/Naturmarket.png";
import AppBar from "@mui/material/AppBar";
import { getToken } from "../http/tokenGET";

export default function AdminSpeciesList() {
    const { data: species, isPending } = useGET(`${process.env.REACT_APP_API_URL}/species`);

    const [selectedItem, setSelectedItem] = useState(null);

    const nameRef = useRef(null);
    const descriptionRef = useRef(null);
    const categoryRef = useRef(null);

    const [speciesType, setSpeciesType] = useState("");

    const [speciesList, setSpeciesList] = useState([]);

    const {
        handleFileChange,
        imageFileEncoded,
        setImageFileEncoded,
    } = ImageHandler();

    const categories = [
        { name: 'Alger' },
        { name: 'Blötdjur' },
        { name: 'Buskar' },
        { name: 'Fåglar' },
        { name: 'Groddjur' },
        { name: 'Gräs/halvgräs' },
        { name: 'Insekter' },
        { name: 'Lavar' },
        { name: 'Mossor' },
        { name: 'Ormbunksväxter' },
        { name: 'Svampar' },
        { name: 'Träd' },
        { name: 'Örter' }
    ];

    useEffect(() => {
        setSpeciesList(species);
    }, [species]);

    useEffect(() => {
        const fetchSpeciesList = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/species`);
                const updatedSpeciesList = await response.json();
                setSpeciesList(updatedSpeciesList);
            } catch (error) {
                console.error("Error fetching species list:", error);
            }
        };

        fetchSpeciesList();
    }, []);

    const handleAdd = () => {
        nameRef.current.value = "";
        descriptionRef.current.value = "";
        categoryRef.current.value = "";
        setSelectedItem(null);
        setImageFileEncoded(null);
        setSpeciesType("");
        nameRef.current.focus();
    };

    function handleClick(selected) {
        nameRef.current.value = selected.name;
        console.log('HandleClick: nameRef.current.value = ' + nameRef.current.value);

        descriptionRef.current.value = selected.description;
        console.log('HandleClick: descriptionRef.current.value = ' + descriptionRef.current.value);

        categoryRef.current.value = selected.category_name;
        console.log('HandleClick: categoryRef.current.value = ' + categoryRef.current.value);

        setImageFileEncoded(selected.data_path);
        setSpeciesType(selected.category_name);
    }

    const handleDeleteSpecies = async () => {
        try {
            const token = await getToken();
            const specieName = nameRef.current.value;
            const response = await fetch(`${process.env.REACT_APP_API_URL}/admin/species/${specieName}`, {
                method: 'DELETE',
                credentials: 'include',
                headers: {
                    "Content-Type": "application/json",
                    'X-CSRF-TOKEN': token
                }
            });

            if (response.status === 200) {
                const updatedSpeciesList = speciesList.filter(specie => specie.name !== specieName);
                setSpeciesList(updatedSpeciesList);
                handleAdd();
            } else {
                console.error(`Failed to delete species: ${response.status} - ${response.statusText}`);
            }
        } catch (error) {
            console.error("Error deleting specie:", error);
        }
    };

    const handleSpeciesCategory = (event) => {
        setSpeciesType(event.target.value);
    };

    const theme = createTheme({
        typography: {
            fontFamily: ["roboto", "sans-serif"].join(","),
        },
        palette: {
            primary: {
                main: "#698681",
            },
            secondary: {
                main: "#d9e0df",
            },
        },
    });

    const handleSave = async () => {
        const updatedSpecies = {
            name: nameRef.current.value,
            description: descriptionRef.current.value,
            data_path: imageFileEncoded,
            category_name: speciesType,
        };

        const url = selectedItem
            ? `${process.env.REACT_APP_API_URL}/admin/species/${selectedItem}` // Uppdatera befintlig art
            : `${process.env.REACT_APP_API_URL}/admin/species`; // Skapa ny art

        const method = selectedItem ? "PUT" : "POST";

        try {
            const token = await getToken();
            const response = await fetch(url, {
                method,
                credentials: 'include',
                headers: {
                    "Content-Type": "application/json",
                    'X-CSRF-TOKEN': token
                },
                body: JSON.stringify(updatedSpecies),
            });

            if (!response.ok) {
                throw new Error(`Error: ${response.status} - ${response.statusText}`);
            }

            const data = await response.json();
            console.log("Response data:", data);

            if (method === "POST" && data && data.species) {
                // Lägg till ny art i listan
                setSpeciesList([...speciesList, data.species]);
            } else if (method === "PUT") {
                // Uppdatera listan lokalt
                setSpeciesList(speciesList.map(specie =>
                    specie.id === selectedItem ? { ...specie, ...updatedSpecies } : specie
                ));
            }
            // Rensar formuläret
            handleAdd();
        } catch (error) {
            console.error("Error adding new specie:", error);
        }
    };

    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 48 * 4.5 + 8,
                width: 250,
            },
        },
    };

    return (
        <ThemeProvider theme={theme}>
            <AppBar position="relative" style={{ backgroundColor: "#8EA534", padding: "10px" }}>
                <Toolbar>
                    {/* Hero Section */}
                    <div style={{ display: "flex", alignItems: "center", padding: "1rem" }}>
                        <img
                            src={NaturmarketImage}
                            alt="Naturmärket"
                            style={{ width: "100px", height: "100px", marginRight: "1rem", borderRadius: "50%" }}
                        />
                        <Typography variant="h3" style={{ color: "white", fontSize: "1.5rem"}}>
                            Naturborgarmärket
                        </Typography>
                    </div>
                </Toolbar>
            </AppBar>
            <Link
                to="/adminHome"
                style={{ textDecoration: "none", position: "absolute", top: 150, left: 0, margin: "16px" }}
            >
                <Button variant="contained" size="large" color="success">
                    Tillbaka
                </Button>
            </Link>
            <CssBaseline />
            <Box bgcolor="white" sx={{ py: 4 }}>
                <Container maxWidth="lg">
                    <Typography
                        component="h1"
                        variant="h4"
                        align="center"
                        sx={{
                            fontFamily: ["roboto", "sans-serif"].join(","),
                            letterSpacing: 2,
                            m: 1,
                            pt: 4,
                            color: "primary",
                        }}
                    >
                        Redigera Artlista
                    </Typography>
                </Container>
            </Box>
            <Container maxWidth="lg" sx={{ pb: 10 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={6}>
                        <Paper
                            sx={{
                                overflow: "auto",
                                width: 1,
                                height: 650,
                                backgroundColor: "white",
                                textAlign: "center",
                                borderColor: "#719977",
                                borderStyle: "solid",
                                borderRadius: "5px",
                                padding: 2,
                            }}
                        >
                            <Grid sx={{ pt: "17px" }} item xs={12}>
                                <Typography
                                    component="h1"
                                    variant="h5"
                                    sx={{
                                        py: 2,
                                        fontFamily: ["roboto", "sans-serif"].join(","),
                                        letterSpacing: 2,
                                        m: 1,
                                    }}
                                >
                                    Artlista
                                </Typography>
                                <Grid item xs={12}>
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        onClick={() => handleAdd()}
                                        sx={{ mb: 3, backgroundColor: "#4CAF50", color: "white" }}
                                    >
                                        Lägg till <AddIcon sx={{ ml: 0.5 }} fontSize="small" />
                                    </Button>
                                    {!isPending && speciesList && (
                                        <List>
                                            {speciesList.map((x) => {
                                                return (
                                                    <Typography
                                                        sx={{
                                                            m: 2,
                                                            py: 2,
                                                            textAlign: "center",
                                                            cursor: "pointer",
                                                            borderColor: "#c7c7c7",
                                                            borderStyle: "solid",
                                                            borderRadius: "5px",
                                                        }}
                                                        key={x.id}
                                                        selected={selectedItem === x.id}
                                                        data={x}
                                                        onClick={() => {
                                                            handleClick(x);
                                                            setSelectedItem(x.id);
                                                        }}
                                                    >
                                                        {x.name}
                                                    </Typography>
                                                );
                                            })}
                                        </List>
                                    )}
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <Paper
                            sx={{
                                overflow: "auto",
                                width: 1,
                                height: 650,
                                backgroundColor: "white",
                                textAlign: "center",
                                borderColor: "#719977",
                                borderStyle: "solid",
                                borderRadius: "5px",
                                padding: 2,
                            }}
                        >
                            <Typography
                                component="h1"
                                variant="h5"
                                sx={{
                                    py: 1,
                                    fontFamily: ["roboto", "sans-serif"].join(","),
                                    letterSpacing: 2,
                                    m: 1,
                                }}
                            >
                                Redigera art
                            </Typography>
                            <TextField
                                inputRef={nameRef}
                                sx={{
                                    width: 0.8,
                                    pb: 5,
                                }}
                                fullWidth
                                placeholder="Art"
                                InputProps={{ sx: { height: 55 } }}
                            />
                            <FormControl sx={{ width: 0.8 }} InputProps={{ sx: { height: 55 } }} color="primary">
                                <InputLabel id="demo-single-checkbox-label">Kategori</InputLabel>
                                <Select
                                    key={speciesType}
                                    style={{ backgroundColor: "white" }}
                                    labelId="demo-single-checkbox-label"
                                    id="demo-single-checkbox"
                                    value={speciesType}
                                    onChange={handleSpeciesCategory}
                                    input={<OutlinedInput label="Filtrera" />}
                                    MenuProps={MenuProps}
                                    inputRef={categoryRef}
                                >
                                    {categories.map((x) => (
                                        <MenuItem key={x.name} value={x.name}>
                                            {x.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <Box
                                sx={{
                                    backgroundColor: "#fff",
                                    pt: 3,
                                    borderRadius: "20px",
                                    borderColor: "white",
                                    borderStyle: "solid",
                                }}
                            >
                                <Box>
                                    <TextField
                                        inputRef={descriptionRef}
                                        multiline
                                        align="center"
                                        sx={{
                                            width: 1,
                                        }}
                                        InputProps={{
                                            sx: {height: "relative"},
                                        }}
                                        placeholder="Beskrivning av art"
                                    />
                                </Box>
                            </Box>
                            <div>
                                <br />
                                {imageFileEncoded && (
                                    <div>
                                        <img alt="not found" width={"460px"} height={"400px"} src={`http://localhost:8000/bilder/${imageFileEncoded}`} />
                                        <br />
                                        <Button
                                            variant="outlined"
                                            size="small"
                                            sx={{ color: "red", borderColor: "red" }}
                                            onClick={() => {
                                                setImageFileEncoded(null);
                                                handleFileChange(null);
                                            }}
                                        >
                                            Ta bort bild
                                        </Button>
                                    </div>
                                )}

                                <Stack sx={{width: 1, pb: 2, pt: 1}} direction="column" spacing={2}>
                                    <label htmlFor="image-file">
                                        <input
                                            type="file"
                                            name="imageUpload"
                                            accept="image/*"
                                            style={{ display: "none" }}
                                            id="image-file"
                                            onChange={handleFileChange}
                                        />
                                        <Button
                                            sx={{ ml: "28px", backgroundColor: "#4CAF50" }}
                                            color="primary"
                                            variant="contained"
                                            component="span"
                                        >
                                            Ladda upp bild <CameraAltIcon fontSize="small" sx={{ ml: 0.5 }} />
                                        </Button>
                                    </label>
                                    <Button color="error" variant="contained" onClick={handleDeleteSpecies}>
                                        Radera Art
                                    </Button>
                                    <Button
                                        sx={{ mr: "28px", backgroundColor: "#4CAF50" }}
                                        color="primary"
                                        variant="contained"
                                        onClick={() => handleSave()}
                                    >
                                        Spara ändring
                                    </Button>
                                </Stack>
                            </div>
                        </Paper>
                    </Grid>
                </Grid>
            </Container>
        </ThemeProvider>
    );
}