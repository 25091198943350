import React, { useEffect, useState } from "react";
import AudioFileIcon from "@mui/icons-material/AudioFile";
import Button from "@mui/material/Button";

const AudioHandler = () => {
    const [audioFile, setAudioFile] = useState(null);
    const [audioFileEncoded, setAudioFileEncoded] = useState(null);

    const handleFileChange = (event) => {
        setAudioFile(event.target.files[0]);
    };

    useEffect(() => {
        if (audioFile !== null && audioFile !== undefined) {
            const reader = new FileReader();

            reader.onloadend = (event) => {
                setAudioFileEncoded(reader.result);
            };

            reader.readAsDataURL(audioFile);
        } else {
            setAudioFileEncoded(null);
        }
    }, [audioFile]);

    const AudioUpload = () => (
        <label htmlFor="audio-file">
            <input
                type="file"
                name="audioUpload"
                accept="audio/*"
                style={{ display: "none" }}
                id="audio-file"
                onChange={handleFileChange}
            />
            <Button variant="contained" component="span" sx={{ ml: 0.5, backgroundColor: "#4CAF50" }}>
                Ladda upp ljud <AudioFileIcon fontSize="small" />
            </Button>
        </label>
    );

    return {
        AudioUpload,
        audioFile,
        audioFileEncoded,
        setAudioFile,
    };
};

export { AudioHandler };
