import { useEffect, useState } from "react";

const ImageHandler = () => {
    const [imageFile, setImageFile] = useState(null);
    const [imageFileEncoded, setImageFileEncoded] = useState(null);

    const handleFileChange = (event) => {
        setImageFile(event.target.files[0]);
    };

    useEffect(() => {
        if (imageFile !== null && imageFile !== undefined) {
            const reader = new FileReader();

            reader.onloadend = () => {
                setImageFileEncoded(reader.result);
            };

            reader.readAsDataURL(imageFile);
        } else {
            setImageFileEncoded(null);
        }
    }, [imageFile]);

    return {
        handleFileChange,
        imageFileEncoded,
        setImageFileEncoded,
    };
};

export { ImageHandler };
