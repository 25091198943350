import { useEffect, useState } from "react";

/**
 * Custom-made React hook that is used whenever a general GET-request is made
 * anywhere in the application.
 * Usually called on initial render of each component to load relevant
 * data that is to be displayed.
 * Returns a custom object with data from the server that can be used to conditionally
 * render the frontend components.
 * NOT TO BE USED FOR POST REQUEST. ONLY USED TO FETCH DATA WITH GET.
 * @param url the URL that the request is sent to
 * @returns {{data: unknown, response: unknown, isPending: boolean, error: unknown}}
 */
const useGET = (url) => {
    const [data, setData] = useState(null);
    const [response, setResponse] = useState(null);
    const [isPending, setIsPending] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const abortCont = new AbortController();

        fetch(url, {
            signal: abortCont.signal,
            method: "GET",
            credentials: "include",
            headers: { "Content-Type": "application/json" },
        })
            .then((res) => {
                setResponse(res);
                if (!res.ok) {
                    res.text().then((x) => {
                        setError(x);
                    });
                    return null;
                } else {
                    return res.json();
                }
            })
            .then((data) => {
                setData(data);
                setIsPending(false);
                setError(null);
            })
            .catch((err) => {
                switch (err.name) {
                    case "AbortError":
                        console.log("fetch aborted in useGET");
                        break;
                    default:
                        setIsPending(false);
                        setError(err.message);
                        console.log("default ERROR case ==> ", err.message);
                        break;
                }
            });
        return () => abortCont.abort();
    }, [url]);

    return { data, isPending, error, response };
};

export default useGET;
