import React, { useState, useEffect, useRef } from "react";
import Modal from "./Modal";
import Button from "@mui/material/Button";
import FormLabel from "@mui/material/FormLabel";
//import CloseIcon from "@mui/icons-material/Close";

const initialModalData = {
    name: "",
};

const SingleFormModal = ({ onSubmit, isOpen, onClose, text }) => {
    initialModalData.name = text;

    const focusInputRef = useRef(null);
    const [formState, setFormState] = useState(initialModalData);

    useEffect(() => {
        if (isOpen && focusInputRef.current) {
            setTimeout(() => {
                focusInputRef.current.focus();
            }, 0);
        }
    }, [isOpen]);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormState((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        onSubmit(formState);
        setFormState(initialModalData);
    };

    return (
        <Modal hasCloseBtn={true} isOpen={isOpen} onClose={onClose}>
            <form onSubmit={handleSubmit} style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                <div className="form-row" style={{ marginBottom: "10px", width: "100%", padding: 20 }}>
                    <FormLabel htmlFor="name">Name</FormLabel>
                    <input
                        ref={focusInputRef}
                        type="text"
                        id="name"
                        name="name"
                        value={formState.name}
                        onChange={handleInputChange}
                        required
                        style={{ width: "100%", height: 40, borderColor: "black", borderRadius: 4 }}
                    />
                </div>
                <div className="form-row">
                    <Button color="primary" variant="contained" type="submit">
                        Submit
                    </Button>
                </div>
            </form>
        </Modal>
    );
};

export default SingleFormModal;
