import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Link, useLocation } from "react-router-dom";
import FormControl from "@mui/material/FormControl";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Paper from "@mui/material/Paper";
import Timer from "./Timer";
import { FormControlLabel, FormLabel } from "@mui/material";
import Player from "../hooks/AudioPlayer";
import { useMemo } from 'react';
import {getToken} from "../http/tokenGET";

const theme = createTheme({
    palette: {
        primary: {
            main: "#4caf50", // Green
        },
        secondary: {
            main: "#e0e0e0", // Light Grey
        },
    },
});

function parentFunctionTest() {
    console.log("==>PARENT<==");
}

// ... (Previous code)

export default function Quiz() {
    const location = useLocation();
    const { speciesOut } = location.state;

    const [currentQuestionNumber, setCurrentQuestionNumber] = useState(0);
    const [currentQuestionObj, setCurrentQuestionObj] = useState();
    const [showScore] = useState(false); //setShowScore
    const [score] = useState(0); //setScore
    const [questions, setQuestions] = useState(null);
    const [imageBase64, setImageBase64] = useState(null);
    const [userAnswers, setUserAnswers] = useState({});
    const [selectedAnswer, setSelectedAnswer] = useState(null);

    useEffect(() => {
        const fetchQuestions = async () => {
            try {
                console.log("speciesOut in fetchQuestions: ", speciesOut)
                const response = await fetch(`http://localhost:8000/getSelectedQuestions?speciesOut=${encodeURIComponent(JSON.stringify(speciesOut))}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });

                const data = await response.json();

                setQuestions(data);
                console.log("data[0]: ", data[0]);
                setCurrentQuestionObj(data[0]);
            } catch (error) {
                console.error("Request failed:", error);
                alert("An error occurred while fetching questions for selected species.");
            }
        };

        fetchQuestions();
    }, [speciesOut]);

    /*useEffect(() => {
        console.log("questions in useEffect 49: ", questions);
        if (questions !== null) {
            setCurrentQuestionObj(questions[currentQuestionNumber]);
        }
    }, [questions, currentQuestionNumber]);*/

    useEffect(() => {
        if (currentQuestionObj !== undefined) {
            setImageBase64(currentQuestionObj.image_path);
        }
    }, [currentQuestionObj]);

    const handlePreviousQuestion = () => {
        if (currentQuestionNumber > 0) {
            setCurrentQuestionNumber(currentQuestionNumber - 1);
            setSelectedAnswer(null); // Reset selected answer when navigating to the previous question
            setCurrentQuestionObj(questions[currentQuestionNumber - 1]);
        }
    };

    const handleNextQuestion = () => {
        if (currentQuestionNumber === 0) {
            //handleStart();
        }
        if (currentQuestionNumber !== questions.length - 1) {
            setCurrentQuestionNumber(currentQuestionNumber + 1);
            setSelectedAnswer(null); // Reset selected answer when navigating to the next question
            setCurrentQuestionObj(questions[currentQuestionNumber + 1]);
        } else {
            postAnswers();
        }
    };

    const handleChange = (event) => {
        const key = currentQuestionObj.id;
        const val = event.target.value;

        setUserAnswers({
            ...userAnswers,
            [key]: val,
        });

        setSelectedAnswer(val);
    };

    function generateResults(userScore) {
        console.log("User's score: ", userScore);
        window.alert("User scored " + userScore + " out of " + questions.length);
    }

    const postAnswers = async () => {
        try {
            const token = await getToken();
            const response = await fetch("http://localhost:8000/quiz/results", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-TOKEN': token
                },
                credentials: 'include',
                body: JSON.stringify({
                    answers: userAnswers,
                    email: localStorage.getItem("email"),
                }),
            });

            if (!response.ok) {
                const errorText = await response.text();
                console.log("Posting user answers failed: ", errorText);
                throw new Error(`Submitting answers failed: ${errorText}`);
            }

            const jsonData = await response.json();
            console.log("jsonData = ", jsonData);
            const userScore = jsonData.correct_answers;
            console.log("correct_answers = ", userScore);

            if (response.status === 200) {
                console.log("User answers submitted correctly.");
                generateResults(userScore);
            }
        } catch (error) {
            console.error("Request failed:", error);
            alert("An error occurred while submitting users answers.");
        }
    };

    const shuffleArray = (array) => {
        const shuffled = [...array]; // Create a copy to avoid mutating the original array
        for (let i = shuffled.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
        }
        return shuffled;
    };

    const shuffledAnswers = useMemo(() => {
        if (!currentQuestionObj) return [];
        return shuffleArray([
            currentQuestionObj.correct_answer,
            currentQuestionObj.wrong_answer1,
            currentQuestionObj.wrong_answer2,
        ]);
    }, [currentQuestionObj]);

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <React.Fragment>
                <Box
                    sx={{
                        bgcolor: "secondary.main",
                        pt: 3,
                    }}
                >
                    <Container maxWidth="md" sx={{ p: 4, backgroundColor: "white" }}>
                        {questions && (
                            <Timer
                                timeCheck={parentFunctionTest}
                                currentNumber={currentQuestionNumber}
                                totalNumQuestions={questions.length}
                            />
                        )}
                        <Box
                            sx={{
                                backgroundColor: " #a9dfbf ",
                                py: 3,
                                borderRadius: 10,
                                boxShadow: "0px 2px 10px rgba(0, 0, 0, 0)",
                            }}
                        >
                            <Box sx={{ m: 5 }}>
                                {questions && (
                                    <Typography variant="h5" align="center" sx={{ color: "#424242" }}>
                                        {currentQuestionObj && currentQuestionObj.question}
                                    </Typography>
                                )}
                            </Box>
                        </Box>
                    </Container>
                </Box>
                <Box bgcolor="secondary.main">
                    <Container sx={{ py: 4, textAlign: "center", backgroundColor: "#fff" }} maxWidth="md">
                        {imageBase64 && (
                            <Paper
                                sx={{
                                    backgroundColor: "#fff",
                                    borderRadius: 10,
                                    p: 2,
                                    boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.4)",
                                }}
                            >
                                <img
                                    src={`http://localhost:8000/img/${imageBase64}`}
                                    alt="Specific animal description"
                                    style={{width: "100%"}}
                                />
                            </Paper>
                        )}


                        {showScore ? (
                            <Box
                                sx={{
                                    backgroundColor: "#fff",
                                    borderRadius: 10,
                                    borderColor: "#4caf50",
                                    borderStyle: "solid",
                                    p: 2,
                                }}
                            >
                                Du fick {score} av {questions.length} rätt{" "}
                            </Box>
                        ) : (
                            <>
                                {currentQuestionObj && (
                                    <FormControl sx={{ width: 1, pb: 1, pt: 1, flexDirection: "column" }}>
                                        <FormLabel id="radio-buttons-group-label" />
                                        <RadioGroup
                                            aria-labelledby="radio-buttons-group-label"
                                            name="radio-buttons-group"
                                            value={selectedAnswer}
                                            onChange={handleChange}
                                        >
                                            {currentQuestionObj.audio !== null ? (
                                                <Player url={currentQuestionObj.audio}></Player>
                                            ) : (
                                                <span></span>
                                            )}
                                            {shuffledAnswers.map((answerOption, index) => (
                                                <Paper
                                                    sx={{
                                                        p: 1,
                                                        textAlign: "center",
                                                        borderRadius: 10,
                                                        mb: 1,
                                                        backgroundColor: selectedAnswer === answerOption ? "#4caf50" : "#fff",
                                                        color: selectedAnswer === answerOption ? "#fff" : "#000",
                                                        boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.4)",
                                                    }}
                                                    variant="outlined"
                                                    key={`${answerOption}-${index}`}

                                                >

                                                    {answerOption.startsWith("data:image") ? ( // remove this? easier if it
                                                        <FormControlLabel
                                                            key={answerOption}
                                                            control={<Radio />}
                                                            sx={{ p: 1 }}
                                                            value={answerOption}
                                                            label={
                                                                <img
                                                                    src={"../img/" + imageBase64}
                                                                    alt="Answer Option"
                                                                    style={{ width: "100%" }}
                                                                />
                                                            }
                                                        />
                                                    ) : (
                                                        <FormControlLabel
                                                            key={answerOption}
                                                            control={<Radio sx={{ display: "none" }} />}
                                                            sx={{ p: 1 }}
                                                            value={answerOption}
                                                            label={answerOption}
                                                        />
                                                    )}
                                                </Paper>
                                            ))}
                                        </RadioGroup>
                                    </FormControl>
                                )}
                            </>
                        )}
                        <Stack
                            sx={{ width: 1, pb: 2, pt: 2 }}
                            direction="row"
                            spacing={2}
                            justifyContent="space-between"
                        >
                            <Link to={`/MarkingPage/${location.areaName}`} style={{ textDecoration: "none" }}>
                                <Button
                                    sx={{ color: "white", backgroundColor: "#f44336", borderRadius: 5 }}
                                    variant="contained"
                                >
                                    Avbryt
                                </Button>
                            </Link>
                            <Button
                                sx={{ color: "white", borderRadius: 5 }}
                                variant="contained"
                                onClick={handlePreviousQuestion}
                                disabled={currentQuestionNumber === 0}
                            >
                                Föregående
                            </Button>
                            <Button
                                sx={{ color: "white", borderRadius: 5, backgroundColor: "#4caf50" }}
                                variant="contained"
                                onClick={handleNextQuestion}
                            >
                                {questions && currentQuestionNumber !== questions.length - 1
                                    ? "Nästa"
                                    : "Skicka in svar"}
                            </Button>
                        </Stack>
                    </Container>
                </Box>
            </React.Fragment>
        </ThemeProvider>
    );
}
