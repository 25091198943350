import React, { useState, useEffect, useCallback } from "react";
import { GoogleMap, useLoadScript, Marker, DrawingManager } from "@react-google-maps/api";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";

const mapContainerStyle = {
    width: "100%",
    height: "542px",
};

const options = {
    disableDefaultUI: true,
    clickableIcons: false,
};

const lib = ["places", "drawing"];

const AdminMapSpecies = ({ updateSpecies, clearSpecies, shouldRender, render, species, center, setReady }) => {
    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
        libraries: lib,
    });
    const [drawingManager, setDrawingManager] = useState(null);
    const [userMarker, setUserMarker] = useState(null);

    const toggleDrawingControls = useCallback((onOff) => {
        switch (onOff) {
            case true:
                drawingManager.setOptions({
                    drawingMode: "marker",
                    drawingControlOptions: {
                        drawingControl: true,
                        drawingModes: ["marker"],
                        position: window.google.maps.ControlPosition.TOP_CENTER,
                    },
                });
                break;
            case false:
                drawingManager.setOptions({
                    drawingMode: null,
                    drawingControlOptions: {
                        drawingControl: false,
                        drawingModes: [],
                        position: window.google.maps.ControlPosition.TOP_CENTER,
                    },
                });
                break;
            default:
                break;
        }
    }, [drawingManager]);

    useEffect(() => {
        if (render && userMarker !== null) {
            userMarker.setMap(null);
            toggleDrawingControls(true);
        }
    }, [render, userMarker, toggleDrawingControls]);

    const onMapLoad = (map) => {
        if (map.getCenter() === null) {
            map.setCenter({ lat: 55.59, lng: 13.02, order: -1 });
        }
    };

    const onDrawingLoad = (drawingManager) => {
        setDrawingManager(drawingManager);
        drawingManager.setOptions({
            drawingControlOptions: {
                drawingModes: ["marker"],
                position: window.google.maps.ControlPosition.TOP_CENTER,
            },
        });
    };

    const onMarkerComplete = (marker) => {
        setUserMarker(marker);
        shouldRender(false);
        toggleDrawingControls(false);
    };

    const onClearMarker = () => {
        if (userMarker !== null) userMarker.setMap(null);
        shouldRender(true);
        clearSpecies();
        toggleDrawingControls(true);
    };

    const handleSave = () => {
        if (userMarker) {
            const position = userMarker.getPosition();
            updateSpecies({
                lat: position.lat(),
                lng: position.lng(),
            });
            setReady(true);
        } else {
            setReady(true);
        }
    };

    if (loadError) return "Error loading maps";
    if (!isLoaded) return <div>Loading...</div>;

    return (
        <div className="admin-map-species">
            <Stack sx={{ pt: 3, width: 1, mb: 2 }} direction="row" spacing={2} justifyContent="center">
                <Button onClick={onClearMarker} variant="outlined">
                    Clear
                </Button>
                <Button onClick={handleSave} variant="outlined">
                    Save
                </Button>
            </Stack>

            <GoogleMap
                onLoad={onMapLoad}
                zoom={13}
                center={center}
                options={options}
                mapContainerStyle={mapContainerStyle}
            >
                {render && species && <Marker position={species}></Marker>}
                <DrawingManager onLoad={onDrawingLoad} onMarkerComplete={onMarkerComplete}></DrawingManager>
            </GoogleMap>
        </div>
    );
};

export default AdminMapSpecies;