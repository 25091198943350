export const getToken = async () => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/token`, {
        credentials: 'include',
    });

    if (!response.ok) {
        console.error('Failed to fetch token');
        throw new Error('Failed to fetch token');
    }

    const data = await response.json();
    return data.token;
};