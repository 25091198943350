import "./css/App.css";
import React from "react";
import Home from "./main/Home";
import SignIn from "./login/SignIn";
import SignUp from "./login/SignUp";
import SignOut from "./login/SignOut";
import UserProfile from "./login/UserProfile";
import Quiz from "./quiz/Quiz";
import AboutNB from "./main/AboutNB";
import MarkingPage from "./quiz/MarkingPage";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import ChooseArea from "./map/ChooseArea";
import NatureMap from "./map/NatureMap";
import AdminHome from "./admin/AdminHome";
import AdminSpeciesList from "./admin/AdminSpeciesList";
import ForgotPassword from "./login/ForgotPassword";
import ResetPassword from "./login/ResetPassword";
import AdminMap from "./admin/AdminMap";
import AccessDenied from "./error/AccessDenied";
import Cookies from "js-cookie";
import AdminNatureMap from "./admin/AdminNatureMap";
import AdminMapSpecies from "./admin/AdminMapSpecies";
import AdminEditQuiz from "./admin/AdminEditQuiz";
import MapTest from "./map/MapTest";
import WelcomePage from "./WelcomePage";
import SpeciesInfo from "./quiz/SpeciesInfo";

// Importera TestCORS-komponenten

/**
 * React component that holds a collection of routes to all other components
 * in the project. React uses this internally when redirecting between different
 * pages for quick access and loading. Any new components that are created needs
 * to be placed here inside <Route> tags with a specified path.
 *
 * Some links need to be restricted so that only ADMIN users can access them, which is
 * currently done by reading the value of a Cookie called 'access'. This is obviously not
 * a valid final solution since anyone can change these values in a browser manually. The actual
 * data inside the component is restricted by a backend role-check on the server, so a new
 * reusable solution for frontend display is mostly needed.
 * @returns {JSX.Element}
 * @constructor
 */
function App() {
    return (
        <Router>
            <div className="App">
                <Switch>
                    <Route exact path="/">
                        <WelcomePage />
                    </Route>

                    <Route exact path="/home">
                        <Home />
                    </Route>

                    <Route exact path="/SignIn">
                        <SignIn />
                    </Route>

                    <Route exact path="/SignUp">
                        <SignUp />
                    </Route>

                    <Route exact path="/SignOut">
                        <SignOut />
                    </Route>

                    <Route exact path="/ResetPassword">
                        <ResetPassword />
                    </Route>

                    <Route exact path="/ForgotPassword">
                        <ForgotPassword />
                    </Route>

                    <Route exact path="/UserProfile">
                        {Cookies.get("access") === "USER" || Cookies.get("access") === "ADMIN" ? (
                            <UserProfile />
                        ) : (
                            <AccessDenied />
                        )}
                    </Route>

                    <Route exact path="/MarkingPage">
                        <MarkingPage />
                    </Route>

                    <Route exact path="/ChooseArea">
                        <ChooseArea />
                    </Route>

                    <Route path="/NatureMap/:centerName">
                        <NatureMap />
                    </Route>

                    <Route exact path="/AdminHome">
                        {Cookies.get("access") === "ADMIN" ? <AdminHome /> : <AccessDenied info={"No admin access"} />}
                    </Route>

                    <Route exact path="/adminmap">
                        {Cookies.get("access") === "ADMIN" ? <AdminMap /> : <AccessDenied />}
                    </Route>

                    <Route exact path="/adminspecieslist">
                        {Cookies.get("access") === "ADMIN" ? <AdminSpeciesList /> : <AccessDenied />}
                    </Route>

                    <Route exact path="/admineditquiz">
                        <AdminEditQuiz />
                    </Route>

                    <Route exact path="/Quiz">
                        <Quiz />
                    </Route>

                    <Route exact path="/AboutNB">
                        <AboutNB />
                    </Route>

                    <Route exact path="/AdminNatureMap">
                        <AdminNatureMap />
                    </Route>

                    <Route exact path="/AdminMapSpecies">
                        <AdminMapSpecies />
                    </Route>

                    <Route exact path="/MapTest">
                        <MapTest />
                    </Route>

                    <Route exact path="/species-info">
                        <SpeciesInfo />
                    </Route>

                </Switch>
            </div>
        </Router>
    );
}

export default App;
