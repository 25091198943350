import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";

/**
 * React component used to manage the time limit when a user starts
 * a quiz. The functionality works, but is currently not connected to
 * anything in the <Quiz> component. When the timer (1800 seconds / 30 minutes)
 * reaches 0, the function passed as a prop from its parent component (<Quiz>) is
 * called. This function is called by 'props.timeCheck();' inside the useEffect that
 * runs every second.
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function Timer(props) {
    const { timeCheck, currentNumber, totalNumQuestions } = props;
    const [seconds, setSeconds] = useState(1800);
    const [isRunning, setIsRunning] = useState(false);
    const [show, setShow] = useState(true);

    useEffect(() => {
        if (isRunning && seconds > 0) {
            const interval = setInterval(() => {
                setSeconds((seconds) => seconds - 1);
            }, 1000);
            return () => clearInterval(interval);
        }
        timeCheck();
    }, [isRunning, seconds, timeCheck]);

    function calcTime() {
        const minutes = Math.floor(seconds / 60);
        const remainingSec = seconds % 60;

        return { minutes, remainingSec };
    }

    const { minutes, remainingSec } = calcTime();

    function handleStart() {
        setIsRunning(true);
    }

    return (
        <>
            {show ? (
                <Button
                    onClick={() => {
                        handleStart();
                        setShow(false);
                    }}
                    variant="contained"
                    size="small"
                    sx={{ mb: 1 }}
                >
                    Starta Quiz
                </Button>
            ) : null}
            <Stack sx={{ width: 1, pb: 1 }} direction="row" justifyContent="space-between">
                <Typography
                    variant="h5"
                    align="center"
                    gutterBottom
                    sx={{
                        fontFamily: ["Roboto", "arial"],
                        letterSpacing: 2,
                        m: 1,
                        color: "primary",
                    }}
                >
                    {minutes}:{remainingSec}
                </Typography>
                <Typography
                    variant="h5"
                    align="center"
                    gutterBottom
                    sx={{
                        fontFamily: ["Roboto", "arial"],
                        letterSpacing: 2,
                        m: 1,
                        color: "primary",
                    }}
                >
                    Fråga {currentNumber + 1}/{totalNumQuestions}
                </Typography>
            </Stack>
        </>
    );
}