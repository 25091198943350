import * as React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import Select from "@mui/material/Select";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import AddIcon from "@mui/icons-material/Add";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import { Link } from "react-router-dom";
import { useEffect, useState, useCallback } from "react";
import useGET from "../http/useGET";
import generalPUT from "../http/generalPUT";
import generalPOST from "../http/generalPOST";
import generalDELETE from "../http/generalDELETE";
import { AudioHandler } from "../hooks/AudioHandler";
import { ImageHandler } from "../hooks/ImageHandler";
import MenuItem from "@mui/material/MenuItem";
import Toolbar from "@mui/material/Toolbar";
import AppBar from "@mui/material/AppBar";
import NaturmarketImage from "../img/Naturmarket.png";
import FormControl from "@mui/material/FormControl";

export default function AdminQuestionsList() {
    const [val, setVal] = React.useState([]);
    const { data: questions } = useGET(`${process.env.REACT_APP_API_URL}/questions`);
    const [selectedItem, setSelectedItem] = useState("");
    const [questionList, setQuestionList] = useState([]);

    const [questionText, setQuestionText] = useState("");
    const [answer1, setAnswer1] = useState("");
    const [answer2, setAnswer2] = useState("");
    const [answer3, setAnswer3] = useState("");

    const [selectedSpecies, setSelectedSpecies] = useState("");

    const {
        handleFileChange: handleFileChangeQ,
        imageFileEncoded: imageFileEncodedQ,
        setImageFileEncoded: setImageFileEncodedQ,
    } = ImageHandler();
    const { handleFileChange: handleFileChange1, imageFileEncoded: imageFileEncoded1 } = ImageHandler();
    const { handleFileChange: handleFileChange2, imageFileEncoded: imageFileEncoded2 } = ImageHandler();
    const { handleFileChange: handleFileChange3, imageFileEncoded: imageFileEncoded3 } = ImageHandler();

    const { AudioUpload, audioFile, audioFileEncoded, setAudioFile } = AudioHandler();

    const { data: species } = useGET(`${process.env.REACT_APP_API_URL}/species`);

    useEffect(() => {
        setQuestionList(questions);
    }, [questions]);

    useEffect(() => {
        const fetchQuestionsList = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/questions`);
                const updatedQuestionsList = await response.json();
                setQuestionList(updatedQuestionsList);
            } catch (error) {
                console.error("Error fetching questions list: ", error);
            }
        };

        fetchQuestionsList();
    }, []);

    const handleClick = useCallback((selected) => {
        console.log("selected in handleClick: ", selected);
        setSelectedItem(selected);
        setQuestionText(selected.question);
        setImageFileEncodedQ(selected.image);
        setAnswer1(selected.correctAnswer);
        setAnswer2(selected.wrongAnswer1);
        setAnswer3(selected.wrongAnswer2);
        setSelectedSpecies(selected.species);
    }, [setImageFileEncodedQ]);

    useEffect(() => {
        if (selectedItem) handleClick(selectedItem);
    }, [selectedItem, handleClick]);

    useEffect(() => {
        if (imageFileEncoded1 != null) {
            setAnswer1("image_url:" + imageFileEncoded1);
        }
    }, [imageFileEncoded1]);

    useEffect(() => {
        if (imageFileEncoded2 != null) {
            setAnswer2("image_url:" + imageFileEncoded2);
        }
    }, [imageFileEncoded2]);

    useEffect(() => {
        if (imageFileEncoded3 != null) {
            setAnswer3("image_url:" + imageFileEncoded3);
        }
    }, [imageFileEncoded3]);

    function handleCheck(id) {
        setSelectedSpecies(id);
    }

    const handleRemoveImage = (imageName) => {
        if (imageName === "answer 1") {
            setAnswer1("");
        } else if (imageName === "answer 2") {
            setAnswer2("");
        } else if (imageName === "answer 3") {
            setAnswer3("");
        }
    };

    const handleSaveChanges = async () => {
        let body = {
            id: questions[selectedItem].id,
            species: selectedSpecies,
            question: questionText,
            image: imageFileEncodedQ,
            audio: audioFileEncoded,
            correctAnswer: answer1,
            wrongAnswer1: answer2,
            wrongAnswer2: answer3,
        };

        try {
            const response = await generalPUT(`${process.env.REACT_APP_API_URL}/admin/question`, body);

            if (response.status === 200) {
                const updatedQuestions = questions.slice();
                updatedQuestions[selectedItem] = body;
                setQuestionList(updatedQuestions);
                setSelectedSpecies(selectedSpecies);
            }
        } catch (error) {
            console.error("Error updating question:", error);
        }
    };

    const handleNewQuestion = async (i) => {
        const inputdata = [...val];

        let body = {
            question: inputdata[i],
            correctAnswer: "",
            wrongAnswer1: "",
            wrongAnswer2: "",
            species_id: selectedSpecies || "",
        };
        console.log(body);

        try {
            const response = await generalPOST(`${process.env.REACT_APP_API_URL}/questions`, body);

            if (response.status === 200) {
                const body = await response.json();
                questions[questions.length] = {
                    id: body.id,
                    question: body.question,
                    image: null,
                    audio: null,
                    correctAnswer: body.correctAnswer,
                    wrongAnswer1: body.wrongAnswer1,
                    wrongAnswer2: body.wrongAnswer2,
                };
                handleDelete(i);
            }
        } catch (error) {
            console.error("Error updating question:", error);
        }
    };

    const handleDeleteQuestion = async () => {
        try {
            const response = await generalDELETE(
                `${process.env.REACT_APP_API_URL}/admin/question/${questions[selectedItem].id}`,
                null,
            );

            if (response.status === 200) {
                const updatedQuestions = questions.slice();
                delete updatedQuestions[selectedItem];
                setQuestionList(updatedQuestions);
            }
        } catch (error) {
            console.error("Error deleting question:", error);
        }
    };

    const handleAdd = () => {
        const newSpecie = [...val, []];
        setVal(newSpecie);
    };
    const handleChange = (onChangeValue, i) => {
        const inputdata = [...val];
        inputdata[i] = onChangeValue.target.value;
        setVal(inputdata);
    };
    const handleDelete = (i) => {
        const deletVal = [...val];
        deletVal.splice(i, 1);
        setVal(deletVal);
    };

    const theme = createTheme({
        typography: {
            fontFamily: ["Arial", "sans-serif"].join(","),
        },
        palette: {
            primary: {
                main: "#698681",
            },
            secondary: {
                main: "#d9e0df",
            },
        },
    });

    return (
        <ThemeProvider theme={theme}>
            <AppBar position="relative" style={{ backgroundColor: "#8EA534", padding: "10px" }}>
                <Toolbar>
                    <div style={{ display: "flex", alignItems: "center", padding: "1rem" }}>
                        <img
                            src={NaturmarketImage}
                            alt="Naturmärket"
                            style={{ width: "100px", height: "100px", marginRight: "1rem", borderRadius: "50%" }}
                        />
                        <Typography variant="h3" style={{ color: "white", fontSize: "1.5rem"}}>
                            Naturborgarmärket
                        </Typography>
                    </div>
                </Toolbar>
            </AppBar>
            <Link
                to="/adminHome"
                style={{ textDecoration: "none", position: "absolute", top: 150, left: 0, margin: "16px" }}
            >
                <Button variant="contained" size="large" color="success">
                    Tillbaka
                </Button>
            </Link>
            <Box
                bgcolor="secondary.main"
                sx={{
                    py: 6,
                }}
            >
                <Container maxWidth="lg">
                    <Typography
                        component="h1"
                        variant="h4"
                        align="center"
                        gutterBottom
                        sx={{
                            fontFamily: ["Arial"],
                            letterSpacing: 2,
                            m: 1,
                            pt: 4,
                            color: "primary",
                        }}
                    >
                        Redigera Quizfrågor
                    </Typography>
                </Container>
            </Box>
            <Container maxWidth="lg" sx={{ mb: 4 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={6}>
                        <Paper
                            sx={{
                                overflow: "auto",
                                width: 1,
                                height: 590,
                                backgroundColor: "white",
                                textAlign: "Center",
                                borderColor: "#4CAF50",
                                borderStyle: "solid",
                                borderRadius: "10px",
                                padding: 2,
                                marginLeft: -2,
                            }}
                        >
                            <Typography
                                component="h1"
                                variant="h5"
                                sx={{
                                    py: 2,
                                    fontFamily: ["Arial"],
                                    letterSpacing: 2,
                                    m: 1,
                                }}
                            >
                                Frågelista
                            </Typography>

                            {questionList && (
                                <List>
                                    {questionList.map((question) => (
                                        <ListItemButton
                                            sx={{ textAlign: "start", fontFamily: "Verdana" }}
                                            key={question.id}

                                            selected={selectedItem === question.id}
                                            onClick={() => {
                                                handleClick(question);
                                                setSelectedItem(question.id);
                                            }}
                                        >
                                            {question.question}
                                        </ListItemButton>
                                    ))}
                                </List>
                            )}

                            <Button
                                color="primary"
                                variant="contained"
                                onClick={() => handleAdd()}
                                sx={{ mb: 3, backgroundColor: "#4CAF50" }}
                            >
                                Lägg till <AddIcon sx={{ ml: 0.5 }} fontSize="small" />
                            </Button>
                            <Grid item xs={12}>
                                {val.map((data, i) => {
                                    return (
                                        <div>
                                            <TextField
                                                sx={{
                                                    width: "100%",
                                                    pb: 1,
                                                }}
                                                multiline
                                                value={data}
                                                onChange={(e) => handleChange(e, i)}
                                                type="text"
                                                id={`service_${i}`}
                                                placeholder="Lägg till fråga"
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="start">
                                                            <DeleteOutlineOutlinedIcon
                                                                onClick={() => handleDelete(i)}
                                                            />
                                                            <button onClick={() => handleNewQuestion(i)}>Skapa</button>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        </div>
                                    );
                                })}
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <Paper
                            sx={{
                                overflow: "auto",
                                width: 1,
                                height: 590,
                                backgroundColor: "white",
                                textAlign: "Center",
                                borderColor: "#4CAF50",
                                borderStyle: "solid",
                                borderRadius: "10px",
                                padding: 2,
                                marginLeft: -2,
                            }}
                        >
                            <Typography
                                component="h1"
                                variant="h5"
                                sx={{
                                    py: 2,
                                    fontFamily: ["Arial"],
                                    letterSpacing: 2,
                                    m: 1,
                                }}
                            >
                                Redigera Fråga
                            </Typography>
                            <TextField
                                sx={{
                                    width: "100%",
                                    pb: 1,
                                }}
                                fullWidth
                                placeholder="Fråga"
                                inputprops={{ sx: { height: 55 } }}
                                value={questionText}
                                onChange={(e) => setQuestionText(e.target.value)}
                            ></TextField>
                            <FormControl sx={{ width: "100%" }} inputprops={{ sx: { height: 55 } }} color="primary">
                                <InputLabel id="select-species-label">Välj art</InputLabel>
                                <Select
                                    labelId="select-species-label"
                                    label="Välj art för att markera"
                                    style={{ width: "100%" }}
                                    value={selectedSpecies || ""}
                                    onChange={(event) => handleCheck(event.target.value)}
                                >
                                    {species &&
                                        species.map((species) => (
                                            <MenuItem key={species.id} value={species.id}>
                                                {species.name}
                                            </MenuItem>
                                        ))}
                                </Select>
                            </FormControl>

                            <Typography
                                fontSize="25px"
                                align="center"
                                gutterBottom
                                sx={{
                                    fontFamily: ["Arial"],
                                    letterSpacing: 2,
                                    mt: 2,
                                }}
                            >
                                Fyll i svarsalternativ
                            </Typography>
                            <Box
                                sx={{
                                    backgroundColor: "#fff",
                                    py: 2,
                                    borderRadius: "20px",
                                    borderColor: "white",
                                    borderStyle: "solid",
                                }}
                            >
                                <Box>
                                    <div>
                                        {answer1 && answer1.startsWith("data:image") ? (
                                            <div>
                                                <img alt="not found" width={"100%"} height={100} src={answer1} />
                                                <button onClick={() => handleRemoveImage("answer 1")}>
                                                    Radera bild
                                                </button>
                                            </div>
                                        ) : (
                                            <div>
                                                <TextField
                                                    sx={{
                                                        width: "100%",
                                                        backgroundColor: "#90ee90",
                                                        mb: 1,
                                                    }}
                                                    placeholder="1"
                                                    value={answer1}
                                                    onChange={(e) => setAnswer1(e.target.value)}
                                                />
                                                <label htmlFor="answer1-file">
                                                    <input
                                                        type="file"
                                                        name="imageUpload"
                                                        accept="image/*"
                                                        style={{ display: "none" }}
                                                        id="answer1-file"
                                                        onChange={handleFileChange1}
                                                    />
                                                    <Button
                                                        color="primary"
                                                        variant="contained"
                                                        component="span"
                                                        sx={{ ml: 0.5, backgroundColor: "#4CAF50" }}
                                                    >
                                                        <CameraAltIcon fontSize="small" />
                                                    </Button>
                                                </label>
                                            </div>
                                        )}
                                    </div>
                                </Box>
                                <Box>
                                    <div>
                                        {answer2 && answer2.startsWith("data:image") ? (
                                            <div>
                                                <img alt="not found" width={100} height={100} src={answer2} />
                                                <button onClick={() => handleRemoveImage("answer 2")}>
                                                    Radera bild
                                                </button>
                                            </div>
                                        ) : (
                                            <div>
                                                <TextField
                                                    sx={{
                                                        width: "100%",
                                                        backgroundColor: "#fb32378c",
                                                        mb: 1,
                                                    }}
                                                    placeholder="X"
                                                    value={answer2}
                                                    onChange={(e) => setAnswer2(e.target.value)}
                                                />
                                                <label htmlFor="answer2-file">
                                                    <input
                                                        type="file"
                                                        name="imageUpload"
                                                        accept="image/*"
                                                        style={{ display: "none" }}
                                                        id="answer2-file"
                                                        onChange={handleFileChange2}
                                                    />
                                                    <Button
                                                        color="primary"
                                                        variant="contained"
                                                        component="span"
                                                        sx={{ ml: 0.5, backgroundColor: "#4CAF50" }}
                                                    >
                                                        <CameraAltIcon fontSize="small" />
                                                    </Button>
                                                </label>
                                            </div>
                                        )}
                                    </div>
                                </Box>
                                <Box>
                                    <div>
                                        {answer3 && answer3.startsWith("data:image") ? (
                                            <div>
                                                <img alt="not found" width={100} height={100} src={answer3} />
                                                <button onClick={() => handleRemoveImage("answer 3")}>
                                                    Radera bild
                                                </button>
                                            </div>
                                        ) : (
                                            <div>
                                                <TextField
                                                    sx={{
                                                        width: "100%",
                                                        backgroundColor: "#fb32378c",
                                                        mb: 1,
                                                    }}
                                                    placeholder="2"
                                                    value={answer3}
                                                    onChange={(e) => setAnswer3(e.target.value)}
                                                />
                                                <label htmlFor="answer3-file">
                                                    <input
                                                        type="file"
                                                        name="imageUpload"
                                                        accept="image/*"
                                                        style={{ display: "none" }}
                                                        id="answer3-file"
                                                        onChange={handleFileChange3}
                                                    />
                                                    <Button
                                                        color="primary"
                                                        variant="contained"
                                                        component="span"
                                                        sx={{ ml: 0.5, backgroundColor: "#4CAF50" }}
                                                    >
                                                        <CameraAltIcon fontSize="small" />
                                                    </Button>
                                                </label>
                                            </div>
                                        )}
                                    </div>
                                </Box>
                            </Box>
                            <div>
                                {imageFileEncodedQ && (
                                    <div>
                                        <img alt="not found" width={"100%px"} height={"100%"} src={imageFileEncodedQ} />
                                        <br />
                                        <Button
                                            variant="outlined"
                                            size="sm"
                                            onClick={() => {
                                                setImageFileEncodedQ(null);
                                                handleFileChangeQ(null);
                                            }}
                                        >
                                            Ta bort
                                        </Button>
                                    </div>
                                )}
                                {audioFile && (
                                    <div>
                                        <audio
                                            alt="not fount"
                                            width={"100%"}
                                            height={"100%"}
                                            src={URL.createObjectURL(audioFile)}
                                        />
                                        <br />
                                        <button variant="outlined" size="sm" onClick={() => setAudioFile(null)}>
                                            Ta bort
                                        </button>
                                    </div>
                                )}
                                <br />
                                <br />
                                <Stack
                                    sx={{ width: 1, pb: 2 }}
                                    direction="row"
                                    spacing={1}
                                    justifyContent="space-around"
                                >
                                    <label htmlFor="image-file">
                                        <input
                                            type="file"
                                            name="imageUpload"
                                            accept="image/*"
                                            style={{ display: "none" }}
                                            id="image-file"
                                            onChange={handleFileChangeQ}
                                        />
                                        <Button
                                            variant="contained"
                                            component="span"
                                            sx={{ width: "100%" , backgroundColor: "#4CAF50" }}
                                        >
                                            Ladda upp bild <CameraAltIcon fontSize="small" />
                                        </Button>
                                    </label>

                                    <AudioUpload />
                                    <Button color="error"
                                            variant="contained"
                                            onClick={handleDeleteQuestion}
                                            sx={{ ml: 0.5, width: "25%", backgroundColor: "red" }}
                                    >
                                        Radera
                                    </Button>
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        onClick={handleSaveChanges}
                                        sx={{ ml: 0.5, width: "25%", backgroundColor: "#4CAF50" }}
                                    >
                                        Spara ändring
                                    </Button>
                                </Stack>
                            </div>
                        </Paper>
                    </Grid>
                </Grid>
            </Container>
        </ThemeProvider>
    );
}
