// https://blog.logrocket.com/creating-reusable-pop-up-modal-react/
// https://stackblitz.com/edit/stackblitz-starters-tkpczr?file=src%2FApp.js,src%2Fcomponents%2FModal%2FModal.css
import React, { useRef, useEffect, useState } from "react";
import Button from "@mui/material/Button";
import "./Modal.css";

const Modal = ({ isOpen, hasCloseBtn = true, onClose, children }) => {
    const [isModalOpen, setModalOpen] = useState(isOpen);
    const modalRef = useRef(null);

    const handleCloseModal = () => {
        if (onClose) {
            onClose();
        }
        setModalOpen(false);
    };

    const handleKeyDown = (event) => {
        if (event.key === "Escape") {
            handleCloseModal();
        }
    };

    useEffect(() => {
        setModalOpen(isOpen);
    }, [isOpen]);

    useEffect(() => {
        const modalElement = modalRef.current;

        if (modalElement) {
            if (isModalOpen) {
                modalElement.showModal();
            } else {
                modalElement.close();
            }
        }
    }, [isModalOpen]);

    return (
        <dialog ref={modalRef} onKeyDown={handleKeyDown} className="modal">
            {hasCloseBtn && (
                <Button color="primary" variant="contained" onClick={handleCloseModal}>
                    Close
                </Button>
            )}
            {children}
        </dialog>
    );
};

export default Modal;
