/**
 * Custom-made React hook that is used whenever a general POST-request is made
 * anywhere in the application.
 * NOT TO BE USED FOR GET REQUEST. ONLY USED TO POST DATA
 * @param url the URL that the request is sent to
 * @param body the formatted JSON that is to be handled by the server
 * @returns {Promise<Response>} a javascript "promise" that can be used to handle the response
 */
export default function generalPOST(url, body) {
    return fetch(url, {
        method: "POST",
        credentials: "include",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(body),
    });
}
