import React from "react";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import { Typography } from "@mui/material";
import { ImageList, ImageListItem } from "@mui/material";
import EU from "../img/EU.jpg";
import Leader from "../img/Leader.jpg";
import SpbSkane from "../img/SpbSkane.png";
import Swedbank from "../img/Swedbank.png";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { Link } from "react-router-dom";
import falsterbostrand from "../img/falsterbostrand.jpg";

const scrollToSection = (sectionId) => {
    const targetSection = document.getElementById(sectionId);
    if (targetSection) {
        targetSection.scrollIntoView({ behavior: "smooth" });
    }
};

const footerStyle = {
    backgroundColor: "#333",
    color: "white",
    padding: "2rem 0",
    textAlign: "center",
    position: "relative",
    width: "100%",
    marginTop: "auto", // Trycker ner footern längst ner på sidan
};

export default function AboutNB() {
    const theme = useTheme();
    useMediaQuery(theme.breakpoints.down("sm"));
    return (
        <>
            <CssBaseline />
            <main>
                {/* Section 1 */}
                <div id="section1" className="section" style={{
                    backgroundImage: `url(${falsterbostrand})`,
                    backgroundSize: 'cover',
                }}>
                    <Link to="/home" style={{textDecoration: "none", position: "absolute", top: 0, left: 0, margin: "16px"}}>
                        <Button variant="contained" size="large" color="success">
                            Tillbaka
                        </Button>
                    </Link>
                    <Container>
                        <h1>Om Naturborgarmärket</h1>
                        <Typography style={{fontSize: "1.2rem"}}>
                            Naturborgarmärket syftar till att utveckla allmänhetens kunskap om olika arter som finns i
                            vår natur. Idén går ut på att skapa ett interaktivt lärande där användaren måste befinna
                            sig i naturen och aktivt leta efter och se olika arter, där kunskapen om dessa arter sedan testas
                            i ett kunskapstest. Om användaren klarar kunskapstestet så belönas detta med ett
                            fysiskt naturborgarmärke som erhålls genom beställning efter avklarat test. Detta kan sedan
                            t.ex. sys på en ryggsäck eller sättas fast på jackan.
                        </Typography>
                    </Container>
                    <div style={{position: "relative", top: "-270px"}}>
                        <Button
                            variant="contained"
                            size="large"
                            color="success"
                            onClick={() => scrollToSection("section2")}
                            style={{width: "200px", padding: "10px", marginTop: "300px"}}
                        >
                            <ArrowDownwardIcon/>
                        </Button>
                    </div>
                </div>

                {/* Section 2 */}
                <div id="section2" className="section">
                    <Container>
                        <h1>Utvecklingen av Naturborgarmärket stödjs av:</h1>
                        <ImageList variant="masonry" cols={3} gap={50}>
                            {[
                                {img: Swedbank, title: "Swedbank"},
                                {img: SpbSkane, title: "Sparbanksstiftelsen Skåne"},
                                {img: EU, title: "EU"},
                                {img: Leader, title: "Leader"},
                            ].map((item) => (
                                <ImageListItem key={item.img}>
                                    <img src={item.img} alt={item.title} loading="lazy" />
                                </ImageListItem>
                            ))}
                        </ImageList>
                    </Container>
                    <div>
                        <Button
                            variant="contained"
                            size="large"
                            color="success"
                            onClick={() => scrollToSection("section1")}
                            style={{ width: "200px", padding: "10px", marginTop: "20px", marginRight: "10px" }}
                        >
                            <ArrowUpwardIcon />
                        </Button>
                        <Button
                            variant="contained"
                            size="large"
                            color="success"
                            onClick={() => scrollToSection("section3")}
                            style={{ width: "200px", padding: "10px", marginTop: "20px" }}
                        >
                            <ArrowDownwardIcon />
                        </Button>
                    </div>
                </div>

                {/* Section 3 */}
                <div id="section3" className="section">
                    <Container>
                        <h1>Naturborgarmärket och allemansrätten</h1>
                        <Typography style={{fontSize: "1.2rem"}}>
                            Tack vare allemansrätten kan vi röra oss fritt i den svenska naturen. Allemansrätten
                            fungerar om alla tar ansvar för att bedöma vad som är lämpligt just här och nu, genom att
                            läsa landskapet och bedöma situationen. Olika aktiviteter, platser och tidpunkter avgör vad
                            du kan göra eller inte. Det är vårt gemensamma ansvar att använda denna gamla
                            sedvana med omtanke. Därför är det viktigt att ta reda på vilka rättigheter och skyldigheter
                            som ingår i allemansrätten. Allemansrätten är ingen lag, däremot omges allemansrätten av
                            lagar och regler som sätter gränser för vad som är tillåtet. I länken nedan kan du läsa mer.
                        </Typography>
                    </Container>
                    <div>
                        <Button
                            variant="contained"
                            size="large"
                            color="success"
                            onClick={() => scrollToSection("section2")}
                            style={{width: "200px", padding: "10px", marginTop: "20px"}}
                        >
                            <ArrowUpwardIcon/>
                        </Button>
                    </div>
                    <div style={footerStyle}>
                        <Typography variant="body2">&copy; {new Date().getFullYear()} Naturborgarmärket</Typography>
                    </div>
                </div>
            </main>
        </>
    );
}