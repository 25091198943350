import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Cookies from "js-cookie";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { getToken } from "../http/tokenGET";
import CryptoJS from "crypto-js"; // Importera CryptoJS



/**
 * React component for Signing in.
 * @returns {JSX.Element}
 * @constructor
 */

export default function SignInSide() {
    const history = useHistory();
    const [errorMessage, setErrorMessage] = useState("");

    const secretKey = "din-hemliga-nyckel"; // Ändra detta till en säker nyckel

    // Funktion för att kryptera cookies
    const encryptCookie = (value) => {
        return CryptoJS.AES.encrypt(value, secretKey).toString();
    };


    const handleSubmit = async (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const user = {
            email: data.get("email"),
            password: data.get("password"),
        };

            try {
                const token = await getToken();
                const response = await fetch(`${process.env.REACT_APP_API_URL}/login`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-CSRF-TOKEN': token,
                    },
                    credentials: 'include',
                    body: JSON.stringify(user),
                });

            

            if (!response.ok) {
                const errorText = await response.text();
                setErrorMessage("Felaktig E-postadress eller Lösenord, vänligen försök igen");
                throw new Error(`Login failed: ${errorText}`);
            }

            const result = await response.json();

            if (result.role === "ADMIN") {
                const encryptedValue = encryptCookie("ADMIN");
                Cookies.set("access", encryptedValue, { secure: true, sameSite: "Strict" });
                history.push("/adminhome");
            } else {
                localStorage.setItem("email", data.get("email"));
                const encryptedValue = encryptCookie("USER");
                Cookies.set("access", encryptedValue, { secure: true, sameSite: "Strict" });
                history.push("/home");
            }
        } catch (error) {
            console.error(error);
            setErrorMessage("Felaktig E-postadress eller Lösenord, vänligen försök igen");
        }
    };

    return (
        <Grid container component="main" sx={{ height: "100vh" }}>
            <Grid
                item
                xs={false}
                sm={4}
                md={7}
                sx={{
                    backgroundImage: "url(https://source.unsplash.com/random/?falsterbo)",
                    backgroundRepeat: "no-repeat",
                    backgroundColor: (t) => (t.palette.mode === "light" ? t.palette.grey[50] : t.palette.grey[900]),
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                }}
            />
            <Grid item xs={12} sm={8} md={5} elevation={6}>
                <Box
                    sx={{
                        my: 8,
                        mx: 4,
                        mt: 13,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <Avatar sx={{ m: 1, bgcolor: "#8EA434" }}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5" sx={{ color: "text.primary", mb: 2 }}>
                        Logga In
                    </Typography>
                    <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="E-postadress"
                            name="email"
                            autoComplete="email"
                            autoFocus
                            sx={{
                                backgroundColor: "white",
                                borderRadius: 1,
                            }}
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Lösenord"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            sx={{
                                backgroundColor: "white",
                                borderRadius: 1,
                            }}
                        />
                        {errorMessage && (
                            <Typography color="error" variant="body2" sx={{ mb: 2 }}>
                                {errorMessage}
                            </Typography>
                        )}
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{
                                mt: 3,
                                mb: 2,
                                color: "white",
                                bgcolor: "#8EA434",
                                "&:hover": {
                                    bgcolor: "#708228",
                                },
                            }}
                        >
                            Logga In
                        </Button>
                        <Grid container>
                            <Grid item xs>
                                <Link href="/ForgotPassword" variant="body2">
                                    Glömt Lösenord?
                                </Link>
                            </Grid>
                            <Grid item>
                                <Link href="/SignUp" variant="body2">
                                    {"Inget konto än? Registrera dig"}
                                </Link>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Grid>
        </Grid>
    );
}
