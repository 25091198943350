import React, { useEffect, useRef } from "react";
import Modal from "./Modal";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

/*const initialModalData = {
    name: "",
}; */

const SingleFormModal = ({ onSubmit, isOpen, onClose, text }) => {
    const focusInputRef = useRef(null);

    useEffect(() => {
        if (isOpen && focusInputRef.current) {
            setTimeout(() => {
                focusInputRef.current.focus();
            }, 0);
        }
    }, [isOpen]);

    const handleSubmit = (event) => {
        event.preventDefault();
        onSubmit();
    };

    return (
        <Modal hasCloseBtn={true} isOpen={isOpen} onClose={onClose}>
            <Typography variant="body1" sx={{ marginBottom: "20px", padding: 5 }}>
                {text}
            </Typography>
            <Button color="primary" variant="contained" onClick={handleSubmit} sx={{ marginTop: "10px" }}>
                Confirm
            </Button>
        </Modal>
    );
};

export default SingleFormModal;
