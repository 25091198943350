import * as React from "react";
import { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import SpeciesInfoDetails from "./SpeciesInfoDetails";
import useGET from "../http/useGET";

/**
 * React component that is embedded in multiple other components as a
 * pop-out box with information about species in a specified area.
 * If no area is specified, the useGET request currently fetches ALL
 * species from the database, which is needed for the species-list on the main page.
 * Also fetches all categories to be able to use the filter functionality.
 * The specifics of a single species is loaded and rendered inside the
 * component called <SpeciesInfoDetails>.
 * @param area
 * @returns {JSX.Element}
 * @constructor
 */
export default function SpeciesInfo({ area }) {
    const {
        data: species,
        /*isPending: speciesPending,
        error: speciesError,
        response: speciesResponse,*/
    } = useGET(`${process.env.REACT_APP_API_URL}/species/` + area);

    const {
        data: categories,
        /*isPending: categoriesPending,
        error: categoriesError,
        response: categoriesResponse,*/
    } = useGET(`${process.env.REACT_APP_API_URL}/categories`);

    const [selectedFilters, setSelectedFilters] = useState([]);

    const handleChanges = (event) => {
        const {
            target: { value },
        } = event;
        setSelectedFilters(
            // On autofill we get a stringified value.
            typeof value === "string" ? value.split(",") : value,
        );
    };

    const [open, setOpen] = useState(true);
    const [fullWidth] = useState(true);
    const [maxWidth] = useState("sm");

    /*const handleClickOpen = () => {
        setOpen(true);
    };*/

    const handleClose = () => {
        setOpen(false);
    };

    //const [setValue] = useState("Controlled");
    return (
        <React.Fragment>
            {/*<Button variant="contained" onClick={handleClickOpen}>*/}
            {/*    Arter*/}
            {/*</Button>*/}
            <Dialog fullWidth={fullWidth} maxWidth={maxWidth} open={open} onClose={handleClose}>
                <DialogTitle
                    align="center"
                    sx={{
                        fontFamily: ["Satisfy"],
                        letterSpacing: 2,
                        m: 1,
                        fontSize: 25,
                        color: "#698681",
                    }}
                >
                    Arter i Naturområde
                </DialogTitle>
                <DialogContent>
                    <Box
                        noValidate
                        component="form"
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            m: "auto",
                            width: "fit-content",
                        }}
                    >
                        <TextField
                            id="filled-search"
                            label="Sök art"
                            type="search"
                            variant="outlined"
                            margin="normal"
                            sx={{ pb: 1 }}
                        />
                        <FormControl sx={{ width: 1, pb: 2 }}>
                            <InputLabel id="demo-multiple-checkbox-label">Filtrera</InputLabel>
                            <Select
                                labelId="demo-multiple-checkbox-label"
                                id="demo-multiple-checkbox"
                                multiple
                                value={selectedFilters}
                                onChange={handleChanges}
                                input={<OutlinedInput label="Filtrera" />}
                                renderValue={(selected) => selected.join(", ")}
                            >
                                {categories &&
                                    categories.map((x) => (
                                        <MenuItem key={x.name} value={x.name}>
                                            <Checkbox checked={selectedFilters.indexOf(x.name) > -1} />
                                            <ListItemText primary={x.name} />
                                        </MenuItem>
                                    ))}
                            </Select>
                        </FormControl>
                        {/* CUSTOM COMPONENT FOR DETAILS IN ACCORDION */}
                        {species &&
                            species
                                .filter(
                                    (species) =>
                                        selectedFilters.length === 0 || selectedFilters.includes(species.category.name),
                                )
                                .map((x) => (
                                    <SpeciesInfoDetails
                                        key={x.name}
                                        name={x.name}
                                        description={x.description}
                                        imgPath={x.imgPath}
                                    />
                                ))}
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Close</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}
