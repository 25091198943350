/**
 * Custom-made React hook that is used whenever a general DELETE-request is made
 * anywhere in the application.
 * NOT TO BE USED FOR GET REQUEST. ONLY USED TO DELETE DATA
 * @param url the URL that the request is sent to
 * @param body the formatted JSON that is to be handled by the server
 * @returns {Promise<Response>} a javascript "promise" that can be used to handle the response
 */
// function generalDELETEwBody(url, dataObj) {
//     return fetch(url, {
//         method: 'DELETE',
//         credentials: 'include',
//         headers: {"Content-Type": "application/json"},
//         body: JSON.stringify(dataObj)
//     })
// }
//
// function generalDELETE(url) {
//     return fetch(url, {
//         method: 'DELETE',
//         credentials: 'include',
//         headers: {"Content-Type": "application/json"}
//     })
// }
//
// export {generalDELETE, generalDELETEwBody};

export default function generalDELETE(url, body) {
    const data = {
        method: "DELETE",
        credentials: "include",
        headers: { "Content-Type": "application/json" },
    };

    if (body != null) {
        data["body"] = JSON.stringify(body);
    }

    return fetch(url, data);
}
