// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal::backdrop {
    background: hsl(0 0% 0% / 50%);
}

.modal {
    max-width: 20rem;
    padding: 2rem;
    border: 0;
    border-radius: 0.5rem;
    position: relative;
    box-shadow: hsl(0 0% 0% / 10%) 0 0 0.5rem 0.25rem;
}

.modal-close-btn {
    font-size: 0.75em;
    position: absolute;
    top: 0.25em;
    right: 0.25em;
}
`, "",{"version":3,"sources":["webpack://./src/modal/Modal.css"],"names":[],"mappings":"AAAA;IACI,8BAA8B;AAClC;;AAEA;IACI,gBAAgB;IAChB,aAAa;IACb,SAAS;IACT,qBAAqB;IACrB,kBAAkB;IAClB,iDAAiD;AACrD;;AAEA;IACI,iBAAiB;IACjB,kBAAkB;IAClB,WAAW;IACX,aAAa;AACjB","sourcesContent":[".modal::backdrop {\n    background: hsl(0 0% 0% / 50%);\n}\n\n.modal {\n    max-width: 20rem;\n    padding: 2rem;\n    border: 0;\n    border-radius: 0.5rem;\n    position: relative;\n    box-shadow: hsl(0 0% 0% / 10%) 0 0 0.5rem 0.25rem;\n}\n\n.modal-close-btn {\n    font-size: 0.75em;\n    position: absolute;\n    top: 0.25em;\n    right: 0.25em;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
