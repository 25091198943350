import * as React from "react";
import {
    Box, Button, Container, CssBaseline, Grid, InputLabel, MenuItem, Paper, Select, Stack, TextField, Typography,
    List, FormControl, OutlinedInput
} from "@mui/material";
import Input from "@mui/material/Input";
import { ThemeProvider, useTheme } from "@mui/material/styles";
import { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { ImageHandler } from "../hooks/ImageHandler";
import { getToken } from "../http/tokenGET";
import useGET from "../http/useGET";
import NaturmarketImage from "../img/Naturmarket.png";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

export default function AdminSpeciesList() {
    const { data: species, isPending } = useGET(`${process.env.REACT_APP_API_URL}/species`);
    const [selectedItem, setSelectedItem] = useState(null);
    const [speciesType, setSpeciesType] = useState("");
    const [speciesName, setSpeciesName] = useState("");
    const [speciesList, setSpeciesList] = useState([]);
    const [specieImages, setSpeciesImages] = useState([]);
    const [newSpecieData, setNewSpecieData] = useState({
            name: "",
            category_name: "",
            description: "",
    });
    const [imageFiles, setImageFiles] = useState([1]);
    const [isAddImageModalOpen, setAddImageModalOpen] = useState(false);
    const [imageInputs, setImageInputs] = useState([1]);
    const nameRef = useRef(null);
    const descriptionRef = useRef(null);
    const imageInputRef = useRef(null);

    const { handleFileChange, imageFileEncoded, setImageFileEncoded } = ImageHandler();

    const categories = [
        'Alger', 'Blötdjur', 'Buskar', 'Fåglar', 'Groddjur', 'Gräs/halvgräs',
        'Insekter', 'Lavar', 'Mossor', 'Ormbunksväxter', 'Svampar', 'Träd', 'Örter', 'Övrigt'
    ];

    useEffect(() => {
        setSpeciesList(species);
    }, [species]);

    useEffect(() => {
        setImageFileEncoded(imageFileEncoded);
    }, [imageFileEncoded, setImageFileEncoded]);

    const handleSpeciesCategory = (event) => {
        setSpeciesType(event.target.value);
    };

    const openAddImageModal = () => {
        setAddImageModalOpen(true);
    };

    const closeAddImageModal = () => {
        setAddImageModalOpen(false);
        setImageFiles([1]);
        //setNewAreaData({ name: "", description: "", image_path: null });
    };

    const handleAdd = () => {
        setSpeciesType("");
        setNewSpecieData((prevState) => ({
            ...prevState,
            name: "",  
            category_name: "",
            description: "",
        }));
    
        setSelectedItem(null);
        setImageFileEncoded("");

        

        if (imageInputRef.current) {
            imageInputRef.current.value = ""; 
        }
    };
    
    const handleClick = async (selected) => {
        setNewSpecieData({
            name: selected.name,
            description: selected.description,
            category_name: selected.category_name,
        });

        setSpeciesName(selected.name);
        setSpeciesType(selected.category_name);
        
        const speciePictures = await retrieveSpecieImages(selected.id);
        setSpeciesImages(speciePictures);
    };

    const retrieveSpecieImages = async (specieId) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/specie-images/${specieId}`);
            
            if (!response.ok) {
                return [];
            }
    
            const imagePaths = await response.json();
            const fullUrls = imagePaths.map(filePath => `${process.env.REACT_APP_API_URL}/${filePath}`);

            return fullUrls;
        } catch (error) {
            console.error("Error retrieving specie images:", error);
            return [];
        }
    };

    const handleDeleteSpecies = async () => {
        try {
            const token = await getToken();
            const specieName = speciesName;
            const response = await fetch(`${process.env.REACT_APP_API_URL}/species/${specieName}`, {
                method: 'DELETE',
                credentials: 'include',
                headers: {
                    "Content-Type": "application/json",
                    'X-CSRF-TOKEN': token
                }
            });

            if (response.status === 200) {
                const updatedSpeciesList = speciesList.filter(specie => specie.name !== specieName);
                setSpeciesList(updatedSpeciesList);
                setSpeciesImages([]);
                handleAdd();
            } else {
                console.error(`Failed to delete species: ${response.status}`);
            }
        } catch (error) {
            console.error("Error deleting specie:", error);
        }
    };

    const handleSave = async () => {
         const updatedSpecies = {
            name:  newSpecieData.name,
            category_name: newSpecieData.category_name,
            description: newSpecieData.description,
        };

        const url = selectedItem
        ? `${process.env.REACT_APP_API_URL}/species/${selectedItem}` // Uppdatera befintlig art
        : `${process.env.REACT_APP_API_URL}/species`; // Skapa ny art

        const method = selectedItem ? "PUT" : "POST";
      
        try {
            const token = await getToken();
            const response = await fetch(url, {
                method,
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-TOKEN': token
                },
                body: JSON.stringify(updatedSpecies),
            });

            if (!response.ok) {
                alert("Error adding the specie!");
            }

            const data = await response.json();

            if (method === "POST" && data && data.species) {
                setSpeciesList([...speciesList, data.species]);
            } else if (method === "PUT") {
                // Uppdatera listan lokalt
                setSpeciesList(speciesList.map(specie =>
                    specie.id === selectedItem ? { ...specie, ...updatedSpecies } : specie
                ));
            }
              
            handleAdd();
        } catch (error) {
            console.error("Error adding new specie:", error);
        }
    };

    const handleImageChange = (event, index) => {
        const files = event.target.files;
        if (files.length > 0) {
            const newImageFiles = [...imageFiles];
            newImageFiles[index] = files[0];
            setImageFiles(newImageFiles);
        }
    };
    
    const handleAddImageInput = () => {
        setImageFiles([...imageFiles, imageFiles.length + 1]);
    };
    
    const handleRemoveImageInput = (index) => {
        const newImageFiles = [...imageFiles];
        newImageFiles.splice(index, 1);
        setImageFiles(newImageFiles);
    };
    
    const handleAddSpeciePictures = async () => {
        if (imageFiles.length === 0) {
            console.error("No images selected");
            alert("No images selected");
            return;
        }

        const formData = new FormData();
        imageFiles.forEach((file) => {
            if (file) formData.append("image_paths[]", file);
        });

        try {
            const token = await getToken();
            const response = await fetch(`${process.env.REACT_APP_API_URL}/species/${selectedItem}/uploadSpecieImages`, {
                method: "POST",
                credentials: "include",
                headers: {
                    'X-CSRF-TOKEN': token
                },
                body: formData,
            });

            const data = await response.json();

            if (!response.ok) {
                console.error("Failed to upload images:", data);
                alert("Failed to upload images");
                closeAddImageModal();
                return;
            } 
                
            alert("Images uploaded successfully!");
            closeAddImageModal();
            const updatedSpeciePictures = await retrieveSpecieImages(selectedItem);
            setSpeciesImages(updatedSpeciePictures);
            //window.location.reload();
            
        } catch (error) {
            console.error("Upload failed:", error);
        }
    };

    const handleDeleteImage = async (imageUrl) => {
        try {
            console.log("Deleting image:", imageUrl);
            const token = await getToken();
            const response = await fetch(imageUrl, {
                method: "DELETE",
                credentials: "include",
                headers: {
                    'X-CSRF-TOKEN': token
                },
            });
    
            if (!response.ok) {
                throw new Error("Failed to delete image");
            }
    
            setSpeciesImages((prevImages) => prevImages.filter((img) => img !== imageUrl));
        } catch (error) {
            console.error("Error deleting image:", error);
        }
    };

    return (
        <ThemeProvider theme={useTheme()}>
            <AppBar position="relative" sx={{ backgroundColor: "#8EA534", padding: "10px" }}>
                <Toolbar>
                    <div style={{ display: "flex", alignItems: "center", padding: "1rem" }}>
                        <img
                            src={NaturmarketImage}
                            alt="Naturmärket"
                            style={{ width: "100px", height: "100px", marginRight: "1rem", borderRadius: "50%" }}
                        />
                        <Typography variant="h3" sx={{ color: "white", fontSize: "1.5rem" }}>
                            Naturborgarmärket
                        </Typography>
                    </div>
                </Toolbar>
            </AppBar>
            <Link
                to="/adminHome"
                style={{ textDecoration: "none", position: "absolute", top: 150, left: 0, margin: "16px" }}
            >
                <Button variant="contained" size="large" color="success">
                    Tillbaka
                </Button>
            </Link>
            <CssBaseline />
            <Box bgcolor="white" sx={{ py: 4 }}>
                <Container maxWidth="lg">
                    <Typography
                        component="h1"
                        variant="h4"
                        align="center"
                        sx={{
                            fontFamily: ["roboto", "sans-serif"].join(","),
                            letterSpacing: 2,
                            m: 1,
                            pt: 4,
                            color: "primary",
                        }}
                    >
                        Redigera Artlista
                    </Typography>
                </Container>
            </Box>
            <Container maxWidth="lg" sx={{ pb: 10 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={6}>
                        <Paper sx={{ overflow: "auto", width: 1, height: 650, backgroundColor: "white", textAlign: "center", borderRadius: "5px", padding: 2 }}>
                            <Typography component="h1" variant="h5" sx={{ py: 2 }}>
                                Artlista
                            </Typography>
                            <Button
                                color="primary"
                                variant="contained"
                                onClick={handleAdd}
                                sx={{ mb: 3, backgroundColor: "#4CAF50", color: "white" }}
                            >
                                Lägg till <AddIcon sx={{ ml: 0.5 }} fontSize="small" />
                            </Button>
                            {!isPending && speciesList && (
                                <List>
                                    {speciesList.map((x) => (
                                        <Typography
                                            sx={{
                                                m: 2,
                                                py: 2,
                                                textAlign: "center",
                                                cursor: "pointer",
                                                borderColor: "#c7c7c7",
                                                borderStyle: "solid",
                                                borderRadius: "5px",
                                            }}
                                            key={x.id}
                                            selected={selectedItem === x.id}
                                            data={x}
                                            onClick={() => {
                                                handleClick(x);
                                                setSelectedItem(x.id);
                                            }}
                                        >
                                            {x.name}
                                        </Typography>
                                    ))}
                                </List>
                            )}
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <Paper sx={{ overflow: "auto", width: 1, height: 650, backgroundColor: "white", textAlign: "center", borderRadius: "5px", padding: 2 }}>
                            <Typography component="h1" variant="h5" sx={{ py: 1 }}>
                                Redigera art
                            </Typography>
                            <TextField
                                sx={{ width: 0.8, pb: 5 }}
                                fullWidth
                                placeholder="Art"
                                value={newSpecieData.name}
                                onChange={(e) => setNewSpecieData({ ...newSpecieData, name: e.target.value })}
                            />
                            <FormControl sx={{ width: 0.8 }}>
                                <InputLabel id="category-label">Kategori</InputLabel>
                                <Select
                                    labelId="category-label"
                                    value={speciesType}
                                    onChange={(e) => {
                                        const selectedCategory = e.target.value;
                                        setSpeciesType(selectedCategory);
                                        setNewSpecieData((prevState) => ({
                                            ...prevState,
                                            category_name: selectedCategory,
                                        }));
                                    }}
                                    input={<OutlinedInput label="Kategori" />}
                                >
                                    {categories.map((category) => (
                                        <MenuItem key={category} value={category}>
                                            {category}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <TextField
                                multiline
                                sx={{ width: 1, pt: 3 }}
                                placeholder="Beskrivning av art"
                                value={newSpecieData.description}
                                onChange={(e) => setNewSpecieData({ ...newSpecieData, description: e.target.value })}
                            />
                            <Stack direction="column" spacing={2} sx={{ pt: 2 }}>
                                <Button color="error" variant="contained" onClick={handleDeleteSpecies}>Radera Art</Button>
                                <Button sx={{ backgroundColor: "#4CAF50" }} color="primary" variant="contained" onClick={handleSave}>Spara ändring</Button>
                            </Stack>
                            {selectedItem && (
                                <Stack direction="column" spacing={2} sx={{ pt: 2 }}>
                                <Button color="primary" variant="contained" onClick={openAddImageModal}>
                                  Lägg till bilder <CameraAltIcon sx={{ ml: 0.5 }} fontSize="small" />
                                </Button>
                                <Dialog open={isAddImageModalOpen} onClose={closeAddImageModal}>
                                  <DialogTitle>Lägg till bilder</DialogTitle>
                                  <DialogContent>
                                    {imageFiles.map((_, index) => (
                                      <Stack direction="row" spacing={2} sx={{ pt: 2 }} key={index}>
                                        <Input
                                          accept="image/*"
                                          type="file"
                                          onChange={(event) => handleImageChange(event, index)}
                                          inputProps={{ accept: "image/*" }}
                                        />
                                        <Button
                                            onClick={() => handleRemoveImageInput(index)}
                                            color="error"
                                            sx={{ minWidth: 'auto', padding: 0 }}
                                        >
                                            <RemoveCircleIcon fontSize="small" />
                                        </Button>
                                      </Stack>
                                    ))}
                                    <Button onClick={handleAddImageInput} color="primary" sx={{ mt: 2 }}>
                                      + Lägg till bild
                                    </Button>
                                  </DialogContent>
                                  <DialogActions>
                                    <Button onClick={closeAddImageModal} color="primary">
                                      Avbryt
                                    </Button>
                                    <Button onClick={handleAddSpeciePictures} color="primary">
                                      Spara
                                    </Button>
                                  </DialogActions>
                                </Dialog>
                              </Stack>
                            )}

                            {specieImages && specieImages.length > 0 && (
                                <Stack direction="column" spacing={2} sx={{ pt: 2 }}>
                                    <Typography variant="h6">Bilder för denna art:</Typography>
                                    <div style={{
                                        display: "flex",
                                        flexWrap: "wrap",
                                        justifyContent: "center",
                                        gap: "16px",  // Adjust gap for even spacing
                                    }}>
                                        {specieImages.map((imageUrl, index) => (
                                            <div key={index} style={{
                                                position: "relative",
                                                width: "200px",  // Fixed size for consistency
                                                height: "200px",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                            }}>
                                                <img
                                                    src={imageUrl}
                                                    alt={`Specie Image ${index + 1}`}
                                                    style={{
                                                        width: "100%",
                                                        height: "100%",
                                                        objectFit: "cover",
                                                        borderRadius: "4px",
                                                    }}
                                                />
                                                <button
                                                    onClick={() => handleDeleteImage(imageUrl)}
                                                    style={{
                                                        position: "absolute",
                                                        top: "5px",
                                                        right: "5px",
                                                        background: "red",
                                                        color: "white",
                                                        border: "none",
                                                        borderRadius: "5px",
                                                        padding: "5px",
                                                        cursor: "pointer",
                                                    }}
                                                >
                                                    🗑️
                                                </button>
                                            </div>
                                        ))}
                                    </div>
                                </Stack>
                            )}
                        </Paper>
                    </Grid>
                </Grid>
            </Container>
        </ThemeProvider>
    );
}
