import { useState } from "react";

export const usePolygon = () => {
    const [polygon, setPolygon] = useState([]);
    const [center, setCenter] = useState({ lat: 55.59, lng: 13.02, order: -1 });
    const [render, shouldRender] = useState(true);

    const updatePolygon = (poly) => {
        const mappedPoly = poly.map(point => ({
            lat: point.latitude,
            lng: point.longitude,
        }));
    
        setPolygon((prevPolygon) => {
            const isSame = 
                prevPolygon.length === mappedPoly.length &&
                prevPolygon.every((point, idx) => 
                    point.lat === mappedPoly[idx].lat && 
                    point.lng === mappedPoly[idx].lng
                );
    
            if (isSame) {
                return prevPolygon;
            }
    
            return mappedPoly;
        });
    };
    

    const clearPolygon = () => {
        setPolygon([]);
    };

    const updateCenter = (center) => {
        setCenter(center);
    };

    return { updatePolygon, clearPolygon, updateCenter, shouldRender, render, polygon, center };
};
