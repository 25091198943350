import * as React from "react";
import {
    Box, Button, Container, CssBaseline, Grid, InputLabel, MenuItem, Paper, Select, Stack, TextField, Typography,
    List, FormControl, OutlinedInput
} from "@mui/material";
import Input from "@mui/material/Input";
import { ThemeProvider, useTheme } from "@mui/material/styles";
import { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import { ImageHandler } from "../hooks/ImageHandler";
import { getToken } from "../http/tokenGET";
import useGET from "../http/useGET";
import NaturmarketImage from "../img/Naturmarket.png";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";

export default function AdminSpeciesList() {
    const { data: species, isPending } = useGET(`${process.env.REACT_APP_API_URL}/species`);
    const [selectedItem, setSelectedItem] = useState(null);
    const [speciesType, setSpeciesType] = useState("");
    const [speciesList, setSpeciesList] = useState([]);
    const [newSpecieData, setNewSpecieData] = useState({
            name: "",
            category_name: "",
            description: "",
            data_path: null,
    });

    const nameRef = useRef(null);
    const descriptionRef = useRef(null);
    const imageInputRef = useRef(null);

    const { handleFileChange, imageFileEncoded, setImageFileEncoded } = ImageHandler();

    const categories = [
        'Alger', 'Blötdjur', 'Buskar', 'Fåglar', 'Groddjur', 'Gräs/halvgräs',
        'Insekter', 'Lavar', 'Mossor', 'Ormbunksväxter', 'Svampar', 'Träd', 'Örter'
    ];

    useEffect(() => {
        setSpeciesList(species);
    }, [species]);

    useEffect(() => {
        setImageFileEncoded(imageFileEncoded);
    }, [imageFileEncoded, setImageFileEncoded]);

    const handleSpeciesCategory = (event) => {
        setSpeciesType(event.target.value);
    };

    const handleAdd = () => {
        setSpeciesType("");
        setNewSpecieData((prevState) => ({
            ...prevState,
            name: "",  
            category_name: "",
            description: "",
            data_path: null,
        }));
    
        setSelectedItem(null);
        setImageFileEncoded("");

        if (imageInputRef.current) {
            imageInputRef.current.value = ""; 
        }
    };
    
    const handleClick = (selected) => {
        setNewSpecieData({
            name: selected.name,
            description: selected.description,
            category_name: selected.category_name,
            data_path: selected.data_path
        });
    
        setSpeciesType(selected.category_name);

        if (imageInputRef.current) {
            imageInputRef.current.value = "";
        }
    };

    const deleteSpecieImage = async () => {
        try {
            const token = await getToken();
            const specieName = nameRef.current.value;
            const specieImage = imageFileEncoded;
            const response = await fetch(`${process.env.REACT_APP_API_URL}/species/${specieName}/image/${specieImage}`,{
                method: 'DELETE',
                credentials: 'include',
                headers: {
                    "Content-Type": "application/json",
                    'X-CSRF-TOKEN': token
                }
            });

            if (response.status === 200) {
                console.log("Image successfully deleted!");
                setImageFileEncoded(null);
                
            } else {
                console.error(`Failed to delete species "${specieName}" image "${specieImage}"`);
                setImageFileEncoded(null);
            }
        } catch (error){
            console.error("Error deleting specie image:", error);
        }
    };

    const handleDeleteSpecies = async () => {
        try {
            const token = await getToken();
            const specieName = nameRef.current.value;
            const response = await fetch(`${process.env.REACT_APP_API_URL}/species/${specieName}`, {
                method: 'DELETE',
                credentials: 'include',
                headers: {
                    "Content-Type": "application/json",
                    'X-CSRF-TOKEN': token
                }
            });

            if (response.status === 200) {
                const updatedSpeciesList = speciesList.filter(specie => specie.name !== specieName);
                setSpeciesList(updatedSpeciesList);
                handleAdd();
            } else {
                console.error(`Failed to delete species: ${response.status}`);
            }
        } catch (error) {
            console.error("Error deleting specie:", error);
        }
    };

    const handleSave = async () => {
         const updatedSpecies = {
        //     name: nameRef.current.value,
        //     description: descriptionRef.current.value,
        //     data_path: imageFileEncoded,
        //     category_name: speciesType,
        };


        const formData = new FormData();
        formData.append("name", newSpecieData.name);
        formData.append("category_name", newSpecieData.category_name);
        formData.append("description", newSpecieData.description);
        formData.append("data_path", newSpecieData.data_path);

        console.log("newSpecieData = ", newSpecieData);

        const url = selectedItem
        ? `${process.env.REACT_APP_API_URL}/species/${selectedItem}` // Uppdatera befintlig art
        : `${process.env.REACT_APP_API_URL}/species`; // Skapa ny art

        const method = selectedItem ? "PUT" : "POST";
      
        try {
            const token = await getToken();
            const response = await fetch(url, {
                method,
                credentials: 'include',
                headers: {
                    'X-CSRF-TOKEN': token
                },
                body: formData,
            });

            if (!response.ok) {
                alert("Error adding the specie!");
            }

            const data = await response.json();

            if (method === "POST" && data && data.species) {
                setSpeciesList([...speciesList, data.species]);
            } else if (method === "PUT") {
                // Uppdatera listan lokalt
                setSpeciesList(speciesList.map(specie =>
                    specie.id === selectedItem ? { ...specie, ...updatedSpecies } : specie
                ));
            }
              
            handleAdd();
        } catch (error) {
            console.error("Error adding new specie:", error);
        }
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setNewSpecieData((prevState) => ({
                ...prevState,
                data_path: file
            }));
        }
    };

    return (
        <ThemeProvider theme={useTheme()}>
            <AppBar position="relative" sx={{ backgroundColor: "#8EA534", padding: "10px" }}>
                <Toolbar>
                    <div style={{ display: "flex", alignItems: "center", padding: "1rem" }}>
                        <img
                            src={NaturmarketImage}
                            alt="Naturmärket"
                            style={{ width: "100px", height: "100px", marginRight: "1rem", borderRadius: "50%" }}
                        />
                        <Typography variant="h3" sx={{ color: "white", fontSize: "1.5rem" }}>
                            Naturborgarmärket
                        </Typography>
                    </div>
                </Toolbar>
            </AppBar>
            <Link
                to="/adminHome"
                style={{ textDecoration: "none", position: "absolute", top: 150, left: 0, margin: "16px" }}
            >
                <Button variant="contained" size="large" color="success">
                    Tillbaka
                </Button>
            </Link>
            <CssBaseline />
            <Box bgcolor="white" sx={{ py: 4 }}>
                <Container maxWidth="lg">
                    <Typography
                        component="h1"
                        variant="h4"
                        align="center"
                        sx={{
                            fontFamily: ["roboto", "sans-serif"].join(","),
                            letterSpacing: 2,
                            m: 1,
                            pt: 4,
                            color: "primary",
                        }}
                    >
                        Redigera Artlista
                    </Typography>
                </Container>
            </Box>
            <Container maxWidth="lg" sx={{ pb: 10 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={6}>
                        <Paper sx={{ overflow: "auto", width: 1, height: 650, backgroundColor: "white", textAlign: "center", borderRadius: "5px", padding: 2 }}>
                            <Typography component="h1" variant="h5" sx={{ py: 2 }}>
                                Artlista
                            </Typography>
                            <Button
                                color="primary"
                                variant="contained"
                                onClick={handleAdd}
                                sx={{ mb: 3, backgroundColor: "#4CAF50", color: "white" }}
                            >
                                Lägg till <AddIcon sx={{ ml: 0.5 }} fontSize="small" />
                            </Button>
                            {!isPending && speciesList && (
                                <List>
                                    {speciesList.map((x) => (
                                        <Typography
                                            sx={{
                                                m: 2,
                                                py: 2,
                                                textAlign: "center",
                                                cursor: "pointer",
                                                borderColor: "#c7c7c7",
                                                borderStyle: "solid",
                                                borderRadius: "5px",
                                            }}
                                            key={x.id}
                                            selected={selectedItem === x.id}
                                            data={x}
                                            onClick={() => {
                                                handleClick(x);
                                                setSelectedItem(x.id);
                                            }}
                                        >
                                            {x.name}
                                        </Typography>
                                    ))}
                                </List>
                            )}
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <Paper sx={{ overflow: "auto", width: 1, height: 650, backgroundColor: "white", textAlign: "center", borderRadius: "5px", padding: 2 }}>
                            <Typography component="h1" variant="h5" sx={{ py: 1 }}>
                                Redigera art
                            </Typography>
                            <TextField
                                //inputRef={nameRef}
                                sx={{ width: 0.8, pb: 5 }}
                                fullWidth
                                placeholder="Art"
                                value={newSpecieData.name}
                                onChange={(e) => setNewSpecieData({ ...newSpecieData, name: e.target.value })}
                            />
                            <FormControl sx={{ width: 0.8 }}>
                                <InputLabel id="category-label">Kategori</InputLabel>
                                <Select
                                    labelId="category-label"
                                    value={speciesType}
                                    onChange={(e) => {
                                        const selectedCategory = e.target.value;
                                        setSpeciesType(selectedCategory);
                                        setNewSpecieData((prevState) => ({
                                            ...prevState,
                                            category_name: selectedCategory, // Update category in newSpecieData
                                        }));
                                    }}
                                    input={<OutlinedInput label="Kategori" />}
                                >
                                    {categories.map((category) => (
                                        <MenuItem key={category} value={category}>
                                            {category}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <TextField
                                //inputRef={descriptionRef}
                                multiline
                                sx={{ width: 1, pt: 3 }}
                                placeholder="Beskrivning av art"
                                value={newSpecieData.description}
                                onChange={(e) => setNewSpecieData({ ...newSpecieData, description: e.target.value })}
                            />
                            <Stack direction="column" spacing={2} sx={{ pt: 2 }}>
                                <Input
                                    accept="image/"
                                    type="file"
                                    onChange={handleImageChange}
                                    inputProps={{ accept: "image/" }}
                                    inputRef={imageInputRef} // Add the ref here
                                />
                            </Stack>
                            <Stack direction="column" spacing={2} sx={{ pt: 2 }}>
                                
                                <Button color="error" variant="contained" onClick={handleDeleteSpecies}>Radera Art</Button>
                                <Button sx={{ backgroundColor: "#4CAF50" }} color="primary" variant="contained" onClick={handleSave}>Spara ändring</Button>
                            </Stack>
                        </Paper>
                    </Grid>
                </Grid>
            </Container>
        </ThemeProvider>
    );
}
