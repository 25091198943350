// SearchAndFilter.js
import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

const SearchAndFilter = ({ searchData, handleSearch, handleFilter, filterOptions }) => {
    const [searchTerm, setSearchTerm] = useState("");
    const [selectedFilter, setSelectedFilter] = useState("");

    const handleSearchChange = (event) => {
        const value = event.target.value;
        setSearchTerm(value);
        handleSearch(value);
    };

    const handleFilterChange = (event) => {
        const value = event.target.value;
        setSelectedFilter(value);
        handleFilter(value);
    };

    return (
        <div id="SearchAndFilter">
            <TextField
                label="Search"
                variant="outlined"
                value={searchTerm}
                onChange={handleSearchChange}
                style={{ marginRight: "16px" }}
            />
            <Select
                value={selectedFilter}
                onChange={handleFilterChange}
                displayEmpty
                inputProps={{ "aria-label": "Filter" }}
                style={{ minWidth: "120px" }}
            >
                <MenuItem value="" disabled>
                    Filter
                </MenuItem>
                {filterOptions.map((option) => (
                    <MenuItem key={option} value={option}>
                        {option}
                    </MenuItem>
                ))}
            </Select>
        </div>
    );
};

export default SearchAndFilter;
