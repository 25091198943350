import React, { useEffect, useState, useMemo, useCallback } from "react";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useLocation } from "react-router-dom";
import FormControl from "@mui/material/FormControl";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Paper from "@mui/material/Paper";
import Timer from "./Timer";
import { FormControlLabel } from "@mui/material";
import { getToken } from "../http/tokenGET";
import Confetti from 'react-confetti';
import { useHistory } from "react-router-dom";

const theme = createTheme({
    palette: {
        primary: { main: "#4caf50" },
        secondary: { main: "#e0e0e0" },
    },
});

export default function Quiz() {
    const location = useLocation();
    const {speciesOut, areaName} = location.state;
    const history = useHistory();
    const [currentQuestionNumber, setCurrentQuestionNumber] = useState(0);
    const [currentQuestionObj, setCurrentQuestionObj] = useState();
    const [questions, setQuestions] = useState(null);
    const [imageBase64, setImageBase64] = useState(null);
    const [userAnswers, setUserAnswers] = useState({});
    const [selectedAnswer, setSelectedAnswer] = useState(null);
    const [showResults, setShowResults] = useState(false);
    const [score, setScore] = useState(0);

    const validateLocation = useCallback((userLocation, areaPins) => {
        const polygon = areaPins.map(pin => [pin.latitude, pin.longitude]);

        if (isPointInPolygon([userLocation.lat, userLocation.lng], polygon)) {
            console.log("User is within the area.");
        } else {
            console.log("User is outside the area.");
            alert("You must be in the selected area to start the quiz.");
            history.push("/home");
        }
    }, [history]);

    const getUserLocation = useCallback((areaPins) => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const userLocation = {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude,
                    };
                    validateLocation(userLocation, areaPins);
                },
                (error) => {
                    console.error("Error getting user location:", error);
                    alert("You need to allow location access to proceed.");
                    history.push("/home");
                }
            );
        } else {
            alert("Geolocation is not supported by this browser.");
        }
    }, [validateLocation, history]);



    useEffect(() => {
        const fetchAreaPins = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/areas/${areaName}/pins`, {
                    method: 'GET',
                    headers: {'Content-Type': 'application/json'},
                });
                const data = await response.json();
                getUserLocation(data);
            } catch (error) {
                console.error("Error fetching questions:", error);
            }
        };
        fetchAreaPins();
    }, [areaName, getUserLocation]);

    

    const isPointInPolygon = (point, vs) => {
        let x = point[0], y = point[1];
        let inside = false;
        for (let i = 0, j = vs.length - 1; i < vs.length; j = i++) {
            let xi = vs[i][0], yi = vs[i][1];
            let xj = vs[j][0], yj = vs[j][1];

            let intersect = ((yi > y) !== (yj > y)) &&
                (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
            if (intersect) inside = !inside;
        }
        return inside;
    };

    useEffect(() => {
        const fetchQuestions = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/getSelectedQuestions?speciesOut=${encodeURIComponent(JSON.stringify(speciesOut))}`, {
                    method: 'GET',
                    headers: {'Content-Type': 'application/json'},
                });
                const data = await response.json();
                setQuestions(data);
                setCurrentQuestionObj(data[0]);
            } catch (error) {
                console.error("Error fetching questions:", error);
                alert("An error occurred while fetching questions for selected species.");
            }
        };
        fetchQuestions();
    }, [speciesOut]);

    useEffect(() => {
        if (currentQuestionObj) {
            setImageBase64(currentQuestionObj.image_path);
        }
    }, [currentQuestionObj]);

    const shuffleArray = (array) => {
        const shuffled = [...array];
        for (let i = shuffled.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
        }
        return shuffled;
    };

    const shuffledAnswers = useMemo(() => {
        if (!currentQuestionObj) return [];
        return shuffleArray([
            currentQuestionObj.correct_answer,
            currentQuestionObj.wrong_answer1,
            currentQuestionObj.wrong_answer2,
        ]);
    }, [currentQuestionObj]);

    const handleChange = (event) => {
        const key = currentQuestionObj.id;
        const val = event.target.value;
        setUserAnswers({...userAnswers, [key]: val});
        setSelectedAnswer(val);
    };

    const handlePreviousQuestion = () => {
        if (currentQuestionNumber > 0) {
            setCurrentQuestionNumber(currentQuestionNumber - 1);
            setSelectedAnswer(null);
            setCurrentQuestionObj(questions[currentQuestionNumber - 1]);
        }
    };

    const handleNextQuestion = () => {
        if (currentQuestionNumber !== (questions ? questions.length - 1 : 0)) {
            setCurrentQuestionNumber(currentQuestionNumber + 1);
            setSelectedAnswer(null);
            setCurrentQuestionObj(questions[currentQuestionNumber + 1]);
        } else {
            postAnswers();
            generateResults();
        }
    };

    const postAnswers = async () => {
        try {
            const token = await getToken();
            const response = await fetch(`${process.env.REACT_APP_API_URL}/quiz/results`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-TOKEN': token
                },
                credentials: 'include',
                body: JSON.stringify({
                    answers: userAnswers,
                    email: localStorage.getItem("email"),
                }),
            });

            if (response.ok) {
                const jsonData = await response.json();
                setScore(jsonData.correct_answers);
            } else {
                console.error("Error posting answers");
                alert("An error occurred while submitting the answers.");
            }
        } catch (error) {
            console.error("Error posting answers:", error);
            alert("An error occurred while posting the answers.");
        }
    };

    const generateResults = () => {
        setShowResults(true);
    };

    if (!questions) {
        return <div>Loading...</div>;  // Show loading if questions are not fetched yet
    }

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline/>
            {showResults ? (
                // Resultatskärmen
                <Box sx={{bgcolor: "#f5f5f5", py: 4, textAlign: "center"}}>
                    {score === questions.length ? (
                        <Confetti width={window.innerWidth} height={window.innerHeight}/>
                    ) : null}
                    <Typography variant="h4" sx={{color: "#4caf50", fontWeight: "bold"}}>
                        Ditt resultat
                    </Typography>
                    <Typography variant="h6" sx={{marginTop: 2}}>
                        Du fick {score} av {questions.length} rätt!
                    </Typography>
                    <Box sx={{marginTop: 4}}>
                        {questions.map((question, index) => (
                            <Box key={question.id} sx={{marginBottom: 2}}>
                                <Typography variant="body1">
                                    {index + 1}. {question.question}
                                </Typography>
                                <Typography
                                    variant="body2"
                                    sx={{
                                        color:
                                            userAnswers[question.id] === question.correct_answer
                                                ? "green"
                                                : "red",
                                    }}
                                >
                                    {userAnswers[question.id] === question.correct_answer
                                        ? "Rätt!"
                                        : `Fel, ditt svar var: ${userAnswers[question.id]}`}
                                </Typography>
                                <Typography variant="body2" sx={{color: "gray"}}>
                                    Rätt svar: {question.correct_answer}
                                </Typography>
                            </Box>
                        ))}
                    </Box>
                    <Stack direction="row" spacing={2} justifyContent="center" sx={{marginTop: 4}}>
                        <Button
                            sx={{backgroundColor: "#4caf50", color: "white"}}
                            variant="contained"
                            onClick={() => history.go(0)} // Starta om quizet
                        >
                            Starta om
                        </Button>
                        <Button
                            sx={{backgroundColor: "#f44336", color: "white"}}
                            variant="contained"
                            onClick={() => history.push("/home")} // Gå till hemsidan
                        >
                            Till startsidan
                        </Button>
                    </Stack>
                </Box>
            ) : (
                // Quiz-sektionen
                <Box sx={{bgcolor: "white", pt: 3}}>
                    <Container maxWidth="md" sx={{p: 4, backgroundColor: "white"}}>
                        {questions && (
                            <Timer
                                currentNumber={currentQuestionNumber}
                                totalNumQuestions={questions.length}
                            />
                        )}
                        <Box sx={{backgroundColor: "#a9dfbf", py: 3, borderRadius: 10}}>
                            <Box sx={{m: 5}}>
                                {questions && (
                                    <Typography
                                        variant="h5"
                                        align="center"
                                        sx={{color: "#424242"}}
                                    >
                                        {currentQuestionObj && currentQuestionObj.question}
                                    </Typography>
                                )}
                            </Box>
                        </Box>
                    </Container>
                    <Container sx={{py: 4, textAlign: "center", backgroundColor: "#fff"}} maxWidth="md">
                        {imageBase64 && (
                            <Paper
                                sx={{
                                    backgroundColor: "#fff",
                                    borderRadius: 10,
                                    p: 2,
                                    boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.4)",
                                }}
                            >
                                <img
                                    src={`${process.env.REACT_APP_API_URL}/img/${imageBase64}`}
                                    alt="Couldn't load properly"
                                    style={{width: "100%", borderRadius: 10}}
                                />
                            </Paper>
                        )}
                        <FormControl sx={{width: 1, pb: 1, pt: 1, flexDirection: "column"}}>
                            <RadioGroup
                                aria-labelledby="radio-buttons-group-label"
                                name="radio-buttons-group"
                                value={selectedAnswer}
                                onChange={handleChange}
                            >
                                {shuffledAnswers.map((answerOption, index) => (
                                    <Paper
                                        sx={{
                                            p: 1,
                                            textAlign: "center",
                                            borderRadius: 10,
                                            mb: 1,
                                            backgroundColor:
                                                selectedAnswer === answerOption ? "#4caf50" : "#fff",
                                            color: selectedAnswer === answerOption ? "#fff" : "#000",
                                            boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.4)",
                                        }}
                                        key={`${answerOption}-${index}`}
                                    >
                                        <FormControlLabel
                                            control={<Radio/>}
                                            sx={{p: 1}}
                                            value={answerOption}
                                            label={answerOption}
                                        />
                                    </Paper>
                                ))}
                            </RadioGroup>
                        </FormControl>
                        <Stack direction="row" spacing={2} justifyContent="space-between" sx={{width: 1}}>
                            <Button
                                sx={{color: "white", backgroundColor: "#f44336", borderRadius: 5}}
                                variant="contained"
                                onClick={handlePreviousQuestion}
                                disabled={currentQuestionNumber === 0}
                            >
                                Föregående
                            </Button>
                            <Button
                                sx={{
                                    color: "white",
                                    borderRadius: 5,
                                    backgroundColor: "#4caf50",
                                }}
                                variant="contained"
                                onClick={handleNextQuestion}
                            >
                                {currentQuestionNumber !== questions.length - 1
                                    ? "Nästa"
                                    : "Skicka in svar"}
                            </Button>
                        </Stack>
                    </Container>
                </Box>
            )}
        </ThemeProvider>
    );
}