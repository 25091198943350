import React, { useState, useEffect } from "react";
import { GoogleMap, LoadScript } from "@react-google-maps/api";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import useGET from "../http/useGET";
import { AppBar } from "../appbar";
import { usePolygon } from "../hooks/usePolygon";

const footerStyle = {
    backgroundColor: "#323232",
    color: "white",
    padding: "40px 0",
    textAlign: "center",
    marginTop: "auto",
};

const defaultCenter = { lat: 62.0, lng: 15.0 };
const defaultZoom = 4;

export default function ChooseArea() {
    const { data: areas } = useGET(`${process.env.REACT_APP_API_URL}/areas`);
    const [areaSpecies, setFilteredSpecies] = useState([]);
    const [filteredAreas, setFilteredAreas] = useState([]);
    const [selectedArea, setSelectedArea] = useState(null);
    const [mapCenter, setMapCenter] = useState(defaultCenter);
    const [mapZoom, setMapZoom] = useState(defaultZoom);
    const [pinsUrl, setPinsUrl] = useState("");
    const [pins, setPins] = useState([]);
    const { updatePolygon, updateCenter } = usePolygon();
    const { data: fetchedPins } = useGET(pinsUrl);
    const [hasCentered, setHasCentered] = useState(false);

    // New states for Dialog management
    const [dialogOpen, setDialogOpen] = useState(false);
    const [selectedSpecies, setSelectedSpecies] = useState(null);

    const [images, setImages] = useState({});

    useEffect(() => {
        if (areas) {
            console.log("Fetched areas:", areas);

            // Fetch images for all areas
            const fetchImages = async () => {
                const imagePromises = areas.map(async (area) => {
                    try {
                        const response = await fetch(`${process.env.REACT_APP_API_URL}/area-images/${area.image_path}`);
                        const blob = await response.blob();
                        console.log("Fetched image:", area.image_path);
                        console.log("Blob:", blob);
                        return { id: area.id, url: URL.createObjectURL(blob) };
                    } catch (error) {
                        console.error("Error fetching image:", error);
                        return { id: area.id, url: "" }; // Handle missing images
                    }
                });

                const imageData = await Promise.all(imagePromises);
                const imageMap = imageData.reduce((acc, img) => {
                    acc[img.id] = img.url;
                    return acc;
                }, {});
                setImages(imageMap);
            };
            
            fetchImages();
        }
        }, [areas]);

    useEffect(() => {
        if (areas) {
            setFilteredAreas(areas);
        }
    }, [areas]);


    const handleView = (area) => {
        setSelectedArea(area);
        setPinsUrl(`${process.env.REACT_APP_API_URL}/areas/${area.name}/pins`);

        // Fetch species for the selected area
        fetch(`${process.env.REACT_APP_API_URL}/species/${area.name}`)
            .then((response) => response.json())
            .then((data) => {
                setFilteredSpecies(data);
            })
            .catch((error) => console.error("Error fetching species:", error));
    };

    const toggleSpeciesDescription = (species) => {
        setSelectedSpecies(species);
        setDialogOpen(true); // Open the dialog with species details
    };

    const handleDialogClose = () => {
        setDialogOpen(false); // Close the dialog
        setSelectedSpecies(null); // Clear the selected species
    };

    useEffect(() => {
        if (fetchedPins && fetchedPins.length > 0) {
            const firstPin = fetchedPins[0];
            if (firstPin.latitude && firstPin.longitude) {
                setMapCenter({ lat: firstPin.latitude, lng: firstPin.longitude });
                setMapZoom(14);
            }
        }
    }, [fetchedPins]);

    useEffect(() => {
        if (selectedArea && pins.length > 0 && !hasCentered) {
            const selectedAreaData = areas.find((area) => area.name === selectedArea);

            if (selectedAreaData) {
                pins.sort((a, b) => a.pin_order - b.pin_order);
                updatePolygon(pins);

                const latitudes = pins.map((pin) => pin.latitude);
                const longitudes = pins.map((pin) => pin.longitude);

                const centerLatitude =
                    latitudes.reduce((sum, lat) => sum + lat, 0) / latitudes.length;
                const centerLongitude =
                    longitudes.reduce((sum, lng) => sum + lng, 0) / longitudes.length;

                updateCenter({ lat: centerLatitude, lng: centerLongitude });

                setHasCentered(true);
            }
        }
    }, [selectedArea, pins, areas, updatePolygon, updateCenter, hasCentered]);

    useEffect(() => {
        if (selectedArea) {
            setPins([]);
        }
    }, [selectedArea]);

    return (
        <>
            <CssBaseline />
            <Box sx={{ minHeight: "100vh", display: "flex", flexDirection: "column" }}>
                <AppBar />

                <main style={{ flex: 1, paddingBottom: "40px" }}>
                    <Container
                        sx={{
                            display: "flex",
                            flexDirection: { xs: "column", md: "row" },
                            alignItems: "flex-start",
                            marginTop: "40px",
                            gap: "20px",
                        }}
                    >
                        <Box
                            sx={{
                                flexShrink: 0,
                                width: { xs: "100%", md: "30%" },
                                padding: "20px",
                                borderRadius: "12px",
                                boxShadow: "0 7px 17px rgba(0, 0, 0, 0.2)",
                                backgroundColor: "#fff",
                                textAlign: "center",
                            }}
                        >
                            <Typography variant="h4" sx={{ color: "#698981", mb: 3 }}>
                                Sök naturområde
                            </Typography>
                            <TextField
                                id="search-field"
                                label="Sök naturområde"
                                type="search"
                                variant="outlined"
                                fullWidth
                                sx={{ mb: 3 }}
                                onChange={(e) => {
                                    const searchTerm = e.target.value.toLowerCase();
                                    setFilteredAreas(
                                        areas.filter((area) =>
                                            area.name.toLowerCase().includes(searchTerm)
                                        )
                                    );
                                }}
                            />
                        </Box>

                        <Box
                            sx={{
                                width: { xs: "100%", md: "70%" },
                                gap: "20px",
                                overflowY: "auto",
                                paddingRight: "10px",
                            }}
                        >
                            <Box
                                sx={{
                                    maxHeight: "400px",
                                    overflowY: "auto",
                                    marginBottom: "20px",
                                }}
                            >
                                {filteredAreas.map((area) => (
                                    <Card key={area.id} sx={{ mb: 2 }}>
                                        <img
                                            src={images[area.id]}
                                            alt={area.name}
                                            style={{ width: "100%", height: "150px", objectFit: "cover" }}
                                        />
                                        <CardContent>
                                            <Typography variant="h6" gutterBottom>
                                                {area.name}
                                            </Typography>
                                            <Typography variant="body2">{area.description}</Typography>
                                        </CardContent>
                                        <CardActions>
                                            <Button onClick={() => handleView(area)}>
                                                Gå till naturområde
                                            </Button>
                                        </CardActions>
                                    </Card>
                                ))}
                            </Box>

                            <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}>
                                <GoogleMap
                                    mapContainerStyle={{
                                        width: "100%",
                                        height: "400px",
                                        marginTop: "5px",
                                    }}
                                    center={mapCenter}
                                    zoom={mapZoom}
                                />
                            </LoadScript>
                        </Box>

                        <Box
                            sx={{
                                maxHeight: "573px",
                                overflowY: "auto",
                                width: { xs: "100%", md: "30%" },
                                marginTop: "20px",
                            }}
                        >
                            <Typography
                                variant="h5"
                                sx={{
                                    marginBottom: "20px",
                                    fontWeight: "bold",
                                    color: "#698981",
                                    textAlign: "center",
                                }}
                            >
                                Artlista
                            </Typography>

                            {areaSpecies.length === 0 ? (
                                <Typography
                                    variant="body1"
                                    sx={{
                                        textAlign: "center",
                                        color: "#555",
                                        fontStyle: "italic",
                                    }}
                                >
                                    Ingen art tillgänglig. Välj ett naturområde för att se arter.
                                </Typography>
                            ) : (
                                areaSpecies.map((species) => (
                                    <Card key={species.id}>
                                        <CardContent>
                                            <Typography variant="h6">{species.name}</Typography>
                                        </CardContent>
                                        <CardActions>
                                            <Button onClick={() => toggleSpeciesDescription(species)}>
                                                Visa info
                                            </Button>
                                        </CardActions>
                                    </Card>
                                ))
                            )}
                        </Box>
                    </Container>
                </main>

                <footer style={footerStyle}>
                    <Typography variant="body2">
                        &copy; {new Date().getFullYear()} Naturborgarmärket
                    </Typography>
                </footer>

                {/* Species Info Dialog */}
                {selectedSpecies && (
                    <Dialog open={dialogOpen} onClose={handleDialogClose}>
                        <DialogTitle>{selectedSpecies.name}</DialogTitle>
                        <DialogContent>
                            <Typography>{selectedSpecies.description}</Typography>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleDialogClose}>Stäng</Button>
                        </DialogActions>
                    </Dialog>
                )}
            </Box>
        </>
    );
}